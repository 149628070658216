body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}
div[class^='SnackbarItem-variantWarning'],
div[class*=' SnackbarItem-variantWarning'] {
  font-family: 'Montserrat';
  height: 75px;
  font-size: 20px;
  margin-bottom: 85px;
  background-color: rgb(221, 94, 40) !important;
}

div[class^='SnackbarItem-action'],
div[class*=' SnackbarItem-action'] {
  margin-left: 0;
  padding-left: 0;
}
