.listPopover {
  opacity: 0;
  position: absolute;
  right: 32px;
  top: 60px;
  visibility: hidden;
  display: block;
  background-color: #ffffff;
  border: 1px solid #f1f1f5;
  border-radius: 8px;
  z-index: 99;
  transition: opacity 367ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    transform 244ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  max-width: 295px;
}
.listPopover.open {
  opacity: 1;
  visibility: visible;
}

.listHeader {
  display: flex;
  justify-content: space-between;
  font-family: Manrope;
  align-items: center;
  padding: 16px;
}

.listTitle {
  font-size: 20px;
  font-weight: 700;
  color: #171725;
}

.markAsRead {
  font-size: 12px;
  font-weight: 600;
  color: #0062ff;
  cursor: pointer;
}

.listNotifications {
  padding: 0;
}

.itemNotification {
  display: flex;
  flex-direction: column;
  position: relative;
  cursor: pointer;
  gap: 8px;
  padding: 16px 16px 16px 32px;
}
.itemNotification:hover {
  background-color: #f0f0f0;
}

.itemNotification.recent::before {
  content: '';
  display: inline-block;
  position: absolute;
  width: 8px;
  height: 8px;
  top: 22px;
  left: 16px;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  background-color: #fc5a5a;
}

.itemHeader {
  display: flex;
  justify-content: space-between;
  font-family: 'Inter', sans-serif;
  align-items: center;
}

.itemTime {
  font-weight: 600;
  font-size: 12px;
  color: #92929d;
}

.textPrimary {
  font-weight: 500;
  font-size: 14px;
  color: #171725;
}
.textSecondary {
  font-weight: 400;
  font-size: 12px;
  color: #696974;
}
