/* Styles.css */

body {
  font-family: 'Manrope', sans-serif;
}

p,
h2,
h3,
h4,
h5,
h6 {
  margin: 0px;
  padding: 0px;
}

nav {
  box-shadow: 0px 1px 0px 0px lightgrey;
  padding: 0px 147px;
  height: 95px;
}

.navbar-light .navbar-nav .nav-link {
  color: black;
  padding: 3px 15px;
  border-radius: 15px;
}

.logo {
  width: 124.21px;
  height: 48px;
}

ul.navbar-nav {
  width: 75%;
  justify-content: center;
  margin-left: 3rem;
}


.login-btn {
  width: 84px;
  height: 48px;
  border-color: #b5b5be;
  color: black;
  margin-right: 20px;
}

.section-1-homepage {
  background-color: #f8f8f8;
}

.section1-head1 {
  font-weight: bold;
  margin-bottom: 20px;
  font-size: 60px;
  padding-top: 100px;
}

.section1-pera {
  margin-bottom: 28px;
  font-size: 20px;
  font-weight: 400;
  line-height: 32px;
  text-align: center;
}

.vector-parent {
  width: 638px;
  height: 24px;
  justify-content: space-around;
}

.vector-img {
  margin-right: 11px;
}

.vector-span {
  font-size: 16px;
}

.search {
  margin-top: 49px;
  width: 538px;
  border: 1px solid #efefef;
  height: 64px;
  padding: 2px;
  background: white;
  border-radius: 4px;
}

.input-group {
  align-items: center;
  padding: 0px 8px 0px 18px;
}

.find-input {
  width: 75%;
  height: 58px;
  border-radius: 0px;
  border: none;
  font-size: 18px;
}

.demand-btn {
  width: 173px;
  height: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 4px;
  background: #0062ff;
}

.bigImg-1 {
  z-index: 1;
  margin-bottom: -14px;
}

.circle-1 {
  position: absolute;
  top: 322px;
  left: 14%;
}
.circle-1-features {
  position: absolute;
  top: 322px;
  left: 14%;
}

.circle-2 {
  position: absolute;
  top: 944px;
  left: 8%;
}

.circle-3 {
  position: absolute;
  top: 496px;
  right: 0%;
}

.circle-3-homepage {
  position: absolute;
  top: 496px;
  right: 0%;
}

.section-2-homepage {
  padding: 120px 215px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.section-2 {
  padding: 120px 215px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.section-head {
  font-weight: 700;
  font-size: 48px;
  line-height: 68px;
}

.simple-pr {
  margin-bottom: 20px;
  color: orange;
  font-size: 20px;
}

.cards-wrap {
  margin: 56px 0px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.centerCard {
  background-color: #ffffff;
  overflow: hidden;
  text-align: center;
  width: 310px;
  height: 671px;
  border: 1px solid #0062ff;
  border-radius: 10px;
  margin: 0px 40px;
}

.sideCard {
  z-index: 1;
  background-color: #ffffff;
  overflow: hidden;
  text-align: center;
  width: 310px;
  height: 625px;
  border: 1px solid #f1f1f5;
  border-radius: 10px;
}

.header {
  padding: 12px 0px;
  height: 46px;
  background: #0062ff;
  border: 1px solid #0062ff;
}

.card-head5 {
  color: white;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 1px;
  text-align: center;
}

.card-head4 {
  margin-top: 29px;
}

.card-pera {
  font-size: 15px;
  margin-top: 10px;
  color: #696974;
}

.card-heading3 {
  font-size: 40px;
  font-weight: 700;
  margin-top: 24px;
}

.card-details {
  margin: 29px 20px 0px 20px;
  padding-top: 24px;
  border-top: 1px solid #f1f1f5;
}

.card-vector-span {
  font-size: 16px;
  font-weight: 200;
}

.check-wrap {
  margin-bottom: 18px;
}

.card-vector {
  width: 20px;
  margin-right: 5px;
  color: orange;
}

.see-link {
  font-weight: bold;
}

.see-link-wrap {
  width: 263px;
  height: 56px;
  margin-bottom: 120px;
}

.line {
  background-color: #e2e2ea;
  width: 1010px;
  height: 1px;
}

/*----- 3 ------*/

.section-3 {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.btns-wrap {
  margin: 42px 0px 72px 0px;
  /* width: 40rem; */
  display: flex;
  justify-content: space-between;
}

.template-btn {
  height: 47px;
  min-width: 197px;
  border-radius: 50px;
  border: none;
  background-color: #efefef;
  padding: 0px 30px;
  font-size: 15px;
}

.template-btn-active {
  border: 1px solid #e87443;
  background: #fdf1ec;
  color: #e87443;
}

.imgs-wrap {
  display: flex;
  justify-content: space-between;
  width: 1010px;
}

.template-img {
  width: 95%;
}

.templateBackgroundColor1 {
  align-items: flex-start;
  background-image: url(../../images/marketing/base@2x.svg);
  background-size: 100% 100%;
  display: flex;
  min-width: 310px;
}
.templateBackgroundColor2 {
  align-items: flex-start;
  background-image: url(../../images/marketing/base-1@2x.svg);
  background-size: 100% 100%;
  display: flex;
  min-width: 310px;
}
.templateBackgroundColor3 {
  align-items: flex-start;
  background-image: url(../../images/marketing/base-2@2x.svg);
  background-size: 100% 100%;
  display: flex;
  min-width: 310px;
}

.tamp-image {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.temp-span {
  font-weight: 500;
  font-size: 16px;
  margin-top: 24px;
  line-height: 19.36px;
}

/*---- footer --*/

.footer {
  margin-top: 100px;
  margin-bottom: 60px;
  display: flex;
}

.footer-logo-wrap {
  width: 18rem;
  margin: 72px 107px 0px 147px;
}

.footer-logo {
  width: 151px;
  margin-left: -1px;
}

.footer-pera {
  font-size: 13px;
  font-weight: 400;
  line-height: 23px;
  text-align: left;
  color: #696974;
}

.item-wrap {
  display: flex;
  margin-top: 84px;
  justify-content: center;
}

.item-wrap-h1 {
  font-size: 14px;
  margin-bottom: 10px;
  color: #92929d;
}

.footer-item {
  text-decoration: none;
  font-size: 14px;
  font-weight: 400;
  line-height: 32px;
  color: #171725;
}

.product {
  margin-right: 76px;
}

.company {
  margin-right: 106px;
}

.social {
  margin-right: 90px;
}

.support {
}

.bottom {
  padding: 0px 147px;
  height: 64px;
  border-top: 1px solid #fbfbfb;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.copyRight {
  font-size: 14px;
  color: #696974;
}

/*------ margins and paddings--------*/

.mr-r-5 {
  margin-right: 5px;
}

.mr-r-10 {
  margin-right: 10px;
}

.mr-r-20 {
  margin-right: 20px;
}

.mr-r-32 {
  margin-right: 32px;
}

.mr-l-20 {
  margin-left: 20px;
}

.mr-t-5 {
  margin-top: 5px;
}

.mr-t-10 {
  margin-top: 10px;
}

.mr-t-20 {
  margin-top: 20px;
}

.mr-t-30 {
  margin-top: 30px;
}

.mr-b-10 {
  margin-bottom: 10px;
}

.mr-b-20 {
  margin-bottom: 20px;
}

.mr-b-30 {
  margin-bottom: 30px;
}

.p-t-b-30 {
  margin-top: 30px;
  margin-bottom: 30px;
}

.pd-t-80 {
  padding-top: 80px;
}

.orange {
  color: orange;
}

@media only screen and (max-width: 450px) {
  .side-image-alignment:before {
    width: 0px;
    height: 0px;
  }
  .side-image-reverse-alignment:before {
    width: 0px;
    height: 0px;
  }
  nav {
    padding: 0px;
    height: 65px;
  }
  .navbar {
    padding: 0px 10px;
  }
  .logo {
    width: 100px;
  }
  .navbar-toggler-icon {
    width: 2.5em;
    height: 2.5em;
  }
  .navbar-toggler {
    padding: 0.1rem 0.2rem;
    font-size: 0.8rem;
  }
  /*----------- 1 ----------*/
  .section1-head1 {
    font-size: 32px;
    padding-top: 35px;
  }
  .section1-pera {
    font-size: 13px;
    margin-bottom: 0px;
  }
  .vector-parent {
    width: auto;
    height: 80px;
    flex-direction: column;
    margin: 20px 0px;
    align-items: flex-start;
  }
  .vector-img {
    width: 14px;
    margin-right: 5px;
  }
  .vector-span {
    font-size: 11px;
    align-self: center;
  }
  .search {
    width: 50%;
    height: 30px;
    margin-top: 20px;
  }
  .input-group {
    padding: 0px;
  }
  .find-input {
    height: 24px;
    font-size: 8px;
  }
  .demand-btn {
    font-size: 7px;
    width: 66px;
  }
  .bigImg-1 {
    width: 100%;
    margin-bottom: -6px;
  }
  .circle-1 {
    display: none;
  }
  .circle-1-features {
    display: none;
  }
  .circle-2 {
    display: none;
  }
  .section-2 {
    padding: 70px 0px;
  }
  .simple-pr {
    font-size: 10px;
    margin-bottom: 15px;
  }
  .section-head {
    font-weight: 600;
    font-size: 21px;
    line-height: 30px;
  }
  .cards-wrap {
    margin: 35px 0px;
    flex-direction: column;
    align-items: center;
  }
  .centerCard {
    width: 180px;
    height: 320px;
    margin: 10px 0px;
  }
  .sideCard {
    width: 180px;
    height: 320px;
  }
  .header {
    padding: 0px 0px;
    height: 23px;
  }
  .card-head5 {
    font-size: 10px;
  }
  .card-head4 {
    margin-top: 12px;
    font-size: 14px;
  }
  .card-pera {
    font-size: 8px;
    margin-top: 6px;
  }
  .card-heading3 {
    font-size: 30px;
    margin-top: 12px;
  }
  .card-details {
    margin: 17px 10px 0px 10px;
    padding-top: 10px;
  }
  .card-vector {
    width: 10px;
  }
  .card-vector-span {
    font-size: 8px;
  }
  .check-wrap {
    margin-bottom: 7px;
  }
  .see-link {
    font-size: 7px;
  }
  .see-arrow {
    width: 7px;
    margin-left: 5px;
  }
  .see-link-wrap {
    width: 50%;
    display: flex;
    height: auto;
    justify-content: center;
    margin-bottom: 70px;
  }
  .line {
    width: 280px;
  }
  /*-------- 4 -----------*/
  .btns-wrap {
    width: 90%;
    margin: 20px 0px;
  }
  .template-btn {
    height: 20px;
    min-width: 88px;
    padding: 0px 10px;
    font-size: 7px;
  }
  .imgs-wrap {
    flex-direction: column;
    align-items: center;
    width: 90%;
  }
  .tamp-image {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 15px;
    width: 200px;
  }
  .template-img {
    width: 95%;
  }
  .temp-span {
    font-size: 10px;
    margin-top: 5px;
  }
  /*--- Footer ------*/
  .footer {
    flex-direction: column;
    padding: 0px 20px 30px 20px;
    align-items: center;
    margin-bottom: 10px;
    margin-top: 60px;
  }
  .footer-logo-wrap {
    width: 65%;
    text-align: center;
    margin: 0px;
  }
  .footer-logo {
    width: 170px;
    margin-bottom: 5px;
  }
  .footer-pera {
    font-size: 13px;
    line-height: 20px;
  }
  .item-wrap {
    width: 100%;
    margin-top: 50px;
  }
  .product {
    margin-right: 30px;
  }
  .company {
    margin-right: 15px;
  }
  .social {
    margin-right: 30px;
  }
  .item-wrap-h1 {
    font-size: 8px;
    margin-bottom: 5px;
  }
  .footer-item {
    font-size: 8px;
    line-height: 21px;
  }
  .bottom {
    height: 30px;
    padding: 0px 40px;
  }
  .copyRight {
    font-size: 6px;
  }
  .bottom-img {
    width: 150px;
  }
}

@media only screen and (min-width: 451px) and (max-width: 480px) {
  .side-image-alignment:before {
    width: 0px;
    height: 0px;
  }
  .side-image-reverse-alignment:before {
    width: 0px;
    height: 0px;
  }
  nav {
    padding: 0px;
    height: 65px;
  }
  .navbar {
    padding: 0px 10px;
  }
  .logo {
    width: 100px;
  }
  .navbar-toggler-icon {
    width: 2.5em;
    height: 2.5em;
  }
  .navbar-toggler {
    padding: 0.1rem 0.2rem;
    font-size: 0.8rem;
  }
  /*----------- 1 ----------*/
  .section1-head1 {
    font-size: 32px;
    padding-top: 35px;
  }
  .section1-pera {
    font-size: 13px;
    margin-bottom: 0px;
  }
  .vector-parent {
    width: auto;
    height: 80px;
    flex-direction: column;
    margin: 20px 0px;
    align-items: flex-start;
  }
  .vector-img {
    width: 14px;
    margin-right: 5px;
  }
  .vector-span {
    font-size: 11px;
    align-self: center;
  }
  .search {
    width: 50%;
    height: 30px;
    margin-top: 20px;
  }
  .input-group {
    padding: 0px;
  }
  .find-input {
    height: 24px;
    font-size: 8px;
  }
  .demand-btn {
    font-size: 7px;
    width: 66px;
  }
  .bigImg-1 {
    width: 100%;
    margin-bottom: -6px;
  }
  .circle-1 {
    display: none;
  }
  .circle-1-features {
    display: none;
  }
  .circle-2 {
    display: none;
  }
  .section-2 {
    padding: 70px 0px;
  }
  .simple-pr {
    font-size: 10px;
    margin-bottom: 15px;
  }
  .section-head {
    font-weight: 600;
    font-size: 21px;
    line-height: 30px;
  }
  .cards-wrap {
    margin: 35px 0px;
  }
  .centerCard {
    width: 140px;
    height: 310px;
    margin: 0px 10px;
  }
  .sideCard {
    width: 140px;
    height: 285px;
  }
  .header {
    padding: 2px 0px;
    height: 27px;
  }
  .card-head4 {
    margin-top: 20px;
    font-size: 11px;
  }
  .card-pera {
    font-size: 6px;
    margin-top: 6px;
  }
  .card-heading3 {
    font-size: 30px;
    margin-top: 9px;
  }
  .card-details {
    margin: 17px 10px 0px 10px;
    padding-top: 10px;
  }
  .card-vector {
    width: 8px;
  }
  .card-vector-span {
    font-size: 8px;
  }
  .check-wrap {
    margin-bottom: 3px;
  }
  .see-link {
    font-size: 7px;
  }
  .see-arrow {
    width: 7px;
    margin-left: 5px;
  }
  .see-link-wrap {
    width: 50%;
    display: flex;
    height: auto;
    justify-content: center;
    margin-bottom: 70px;
  }
  .line {
    width: 350px;
  }
  /*-------- 4 -----------*/
  .btns-wrap {
    width: 24rem;
    margin: 20px 0px;
  }
  .template-btn {
    height: 23px;
    min-width: 112px;
    padding: 0px 15px;
    font-size: 9px;
  }
  .imgs-wrap {
    flex-direction: column;
    align-items: center;
    width: 90%;
  }
  .tamp-image {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 15px;
    width: 200px;
  }
  .template-img {
    width: 95%;
  }
  .temp-span {
    font-size: 10px;
    margin-top: 5px;
  }
  /*--- Footer ------*/
  .footer {
    flex-direction: column;
    padding: 0px 20px 30px 20px;
    align-items: center;
    margin-bottom: 10px;
    margin-top: 60px;
  }
  .footer-logo-wrap {
    width: 65%;
    text-align: center;
    margin: 0px;
  }
  .footer-logo {
    width: 170px;
    margin-bottom: 5px;
  }
  .footer-pera {
    font-size: 13px;
    line-height: 20px;
  }
  .item-wrap {
    width: 100%;
    margin-top: 50px;
  }
  .product {
    margin-right: 55px;
  }
  .company {
    margin-right: 65px;
  }
  .social {
    margin-right: 45px;
  }
  .item-wrap-h1 {
    font-size: 9px;
  }
  .footer-item {
    font-size: 9px;
    line-height: 21px;
  }
  .bottom {
    height: 40px;
    padding: 0px 40px;
  }
  .copyRight {
    font-size: 8px;
  }
  .bottom-img {
    width: 170px;
  }
}

@media only screen and (min-width: 481px) and (max-width: 768px) {
  .side-image-alignment:before {
    width: 0px;
    height: 0px;
  }
  .side-image-reverse-alignment:before {
    width: 0px;
    height: 0px;
  }
  nav {
    box-shadow: 0px 1px 0px 0px lightgrey;
    padding: 0px;
    height: 60px;
  }
  .navbar {
    padding: 0px 10px;
  }
  .logo {
    width: 140px;
  }
  /*----------- 1 ----------*/
  .section1-head1 {
    font-size: 30px;
    padding-top: 35px;
  }
  .section1-pera {
    font-size: 15px;
    margin-bottom: 0px;
    line-height: 25px;
  }
  .vector-parent {
    width: auto;
    height: 70px;
    flex-direction: column;
    margin: 20px 0px;
    align-items: flex-start;
  }
  .vector-img {
    width: 13px;
    margin-right: 5px;
  }
  .vector-span {
    font-size: 11px;
  }
  .search {
    width: 260px;
    height: 33px;
    margin-top: 20px;
  }
  .input-group {
    padding: 0px 0px 0px 0px;
  }
  .find-input {
    height: 27px;
    border-radius: 0px;
    border: none;
    font-size: 13px;
  }
  .demand-btn {
    font-size: 9px;
    width: 80px;
  }
  .bigImg-1 {
    z-index: 1;
    margin-bottom: -6px;
    width: 100%;
  }
  .circle-1 {
    display: none;
  }
  .circle-1-features {
    display: none;
  }
  .circle-2 {
    display: none;
  }
  .section-2 {
    padding: 70px 0px;
  }
  .simple-pr {
    font-size: 13px;
    margin-bottom: 15px;
  }
  .section-head {
    font-weight: 600;
    font-size: 23px;
    line-height: 30px;
  }
  .cards-wrap {
    margin: 40px 0px;
  }
  .centerCard {
    width: 140px;
    height: 325px;
    margin: 0px 10px;
  }
  .sideCard {
    width: 140px;
    height: 300px;
  }
  .header {
    padding: 2px 0px;
    height: 27px;
  }
  .card-head4 {
    margin-top: 20px;
    font-size: 13px;
  }
  .card-pera {
    font-size: 6px;
    margin-top: 6px;
  }
  .card-heading3 {
    font-size: 32px;
    margin-top: 9px;
  }
  .card-details {
    margin: 17px 10px 0px 10px;
    padding-top: 10px;
  }
  .check-wrap {
    margin-bottom: 5px;
  }
  .card-vector {
    width: 12px;
  }
  .card-vector-span {
    font-size: 7px;
  }
  .see-link {
    font-size: 7px;
    font-weight: bold;
  }
  .see-arrow {
    width: 7px;
    margin-left: 5px;
  }
  .see-link-wrap {
    width: 20%;
    display: flex;
    justify-content: center;
    height: auto;
    margin-bottom: 70px;
  }
  .line {
    width: 600px;
  }
  /*-------- 4 -----------*/
  .btns-wrap {
    width: 80%;
    margin: 40px 0px;
    justify-content: space-evenly;
  }
  .template-btn {
    height: 25px;
    min-width: 120px;
    padding: 0px 15px;
    font-size: 8px;
  }
  .imgs-wrap {
    flex-direction: row;
    width: 90%;
  }
  .tamp-image {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 15px;
    width: 200px;
  }
  .template-img {
    width: 95%;
  }
  .temp-span {
    font-size: 9px;
    margin-top: 5px;
  }
  /*--- Footer ------*/
  .footer {
    flex-direction: column;
    padding: 0px 20px 30px 20px;
    align-items: center;
    margin-bottom: 10px;
    margin-top: 60px;
  }
  .footer-logo-wrap {
    width: 39%;
    text-align: center;
    margin: 0px;
  }
  .footer-logo {
    width: 170px;
    margin-bottom: 5px;
  }
  .footer-pera {
    font-size: 13px;
    line-height: 20px;
  }
  .product {
    margin-right: 55px;
  }
  .company {
    margin-right: 65px;
  }
  .social {
    margin-right: 45px;
  }
  .item-wrap {
    width: 100%;
    margin-top: 50px;
  }
  .item-wrap-h1 {
    font-size: 9px;
  }
  .footer-item {
    font-size: 9px;
    line-height: 21px;
  }
  .bottom {
    height: 50px;
    padding: 0px 40px;
  }
  .copyRight {
    font-size: 10px;
  }
  .bottom-img {
    width: 200px;
  }
}

@media only screen and (min-width: 769px) and (max-width: 1024px) {
  .side-image-alignment:before {
    width: 0px;
    height: 0px;
  }
  .side-image-reverse-alignment:before {
    width: 0px;
    height: 0px;
  }
  nav {
    box-shadow: 0px 1px 0px 0px lightgrey;
    padding: 10px 5px;
  }
  .logo {
    width: 140px;
  }
  /*----------- 1 ----------*/
  .section1-head1 {
    font-size: 60px;
    padding-top: 50px;
  }
  .section1-pera {
    font-size: 24px;
    margin-bottom: 0px;
  }
  .vector-parent {
    width: 85;
    margin: 20px 0px;
  }
  .vector-img {
    width: 20px;
    margin-right: 8px;
  }
  .vector-span {
    font-size: 18px;
  }
  .search {
    width: 55%;
    height: 50px;
    margin-top: 30px;
  }
  .find-input {
    height: 44px;
    border-radius: 0px;
    border: none;
    font-size: 16px;
  }
  .demand-btn {
    font-size: 16px;
    border: none;
    border-radius: 3px !important;
  }
  .bigImg-1 {
    z-index: 1;
    margin-bottom: -6px;
    width: 100%;
  }
  .circle-1 {
    display: none;
  }
  .circle-1-features {
    display: none;
  }
  .circle-2 {
    display: none;
  }
  .section-2 {
    padding: 50px 0px;
  }
  .simple-pr {
    font-size: 20px;
    margin-bottom: 20px;
  }
  .section-head {
    font-weight: 600;
    font-size: 44px;
    line-height: 52px;
  }
  .cards-wrap {
    margin: 40px 0px;
  }
  .centerCard {
    width: 160px;
    height: 345px;
    margin: 0px 10px;
  }
  .sideCard {
    width: 160px;
    height: 320px;
  }
  .header {
    padding: 2px 0px;
    height: 27px;
  }
  .card-head4 {
    margin-top: 20px;
    font-size: 15px;
  }
  .card-pera {
    font-size: 7px;
    margin-top: 6px;
  }
  .card-heading3 {
    font-size: 33px;
    margin-top: 9px;
  }
  .card-details {
    margin: 17px 10px 0px 10px;
    padding-top: 10px;
  }
  .check-wrap {
    margin-bottom: 3px;
  }
  .card-vector {
    width: 14px;
  }
  .card-vector-span {
    font-size: 7px;
  }
  .see-link {
    font-size: 16px;
    font-weight: bold;
  }
  .see-arrow {
    width: 15px;
    margin-left: 5px;
  }
  .see-link-wrap {
    width: 70%;
  }
  .line {
    width: 550px;
  }
  /*-------- 4 -----------*/
  .btns-wrap {
    width: 70%;
    margin: 50px 0px;
  }
  .template-btn {
    height: 50px;
    min-width: 180px;
    padding: 0px 15px;
    font-size: 13px;
  }
  .imgs-wrap {
    flex-direction: row;
    width: 100%;
  }
  .tamp-image {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 15px;
    width: 200px;
  }
  .template-img {
    width: 95%;
  }
  .temp-span {
    font-size: 16px;
    margin-top: 10px;
  }
  /*--- Footer ------*/
  .footer {
    display: flex;
    flex-direction: column;
    padding: 50px 20px 30px 20px;
    align-items: center;
  }
  .footer-logo-wrap {
    width: 55%;
    text-align: center;
  }
  .footer-logo {
    width: 170px;
    margin-bottom: 5px;
  }
  .footer-pera {
    font-size: 22px;
    line-height: 29px;
  }
  .item-wrap {
    width: 90%;
    margin-top: 50px;
  }
  .item-wrap-h1 {
    font-size: 18px;
  }
  .footer-item {
    font-size: 18px;
    line-height: 35px;
  }
  .bottom {
    height: 50px;
    padding: 0px 40px;
  }
  .copyRight {
    font-size: 12px;
  }
  .bottom-img {
    width: 250px;
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1200px) {
  nav {
    box-shadow: 0px 1px 0px 0px lightgrey;
    padding: 10px 5px;
  }
  .logo {
    width: 140px;
  }
  /*----------- 1 ----------*/
  .section1-head1 {
    font-size: 60px;
    padding-top: 50px;
  }
  .section1-pera {
    font-size: 24px;
    margin-bottom: 0px;
  }
  .vector-parent {
    width: 85;
    margin: 20px 0px;
  }
  .vector-img {
    width: 20px;
    margin-right: 8px;
  }
  .vector-span {
    font-size: 18px;
  }
  .search {
    width: 55%;
    height: 50px;
    margin-top: 30px;
  }
  .find-input {
    height: 44px;
    border-radius: 0px;
    border: none;
    font-size: 16px;
  }
  .demand-btn {
    font-size: 16px;
    border: none;
    border-radius: 3px !important;
  }
  .bigImg-1 {
    z-index: 1;
    margin-bottom: -6px;
    width: 100%;
  }
  .circle-1 {
    display: none;
  }
  .circle-1-features {
    display: none;
  }
  .circle-2 {
    display: none;
  }
  .section-2 {
    padding: 50px 0px;
  }
  .simple-pr {
    font-size: 20px;
    margin-bottom: 20px;
  }
  .section-head {
    font-weight: 600;
    font-size: 50px;
  }
  .see-link {
    font-size: 16px;
    font-weight: bold;
  }
  .see-arrow {
    width: 15px;
    margin-left: 5px;
  }
  .see-link-wrap {
    padding-bottom: 70px;
    width: 70%;
    border-bottom: 1px solid #efefef;
  }
  /*-------- 4 -----------*/
  .btns-wrap {
    width: 70%;
    margin: 50px 0px;
  }
  .template-btn {
    min-width: 220px;
    padding: 0px 15px;
    font-size: 15px;
  }
  .imgs-wrap {
    flex-direction: row;
    width: 80%;
  }
  .tamp-image {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 15px;
    width: 200px;
  }
  .template-img {
    width: 95%;
  }
  .temp-span {
    font-size: 16px;
    margin-top: 10px;
  }
  /*--- Footer ------*/
  .footer {
    display: flex;
    padding: 100px 30px 30px 30px;
    align-items: flex-start;
  }
  .footer-logo-wrap {
    width: 48%;
    margin: 72px 135px 0px 0px;
    background: antiquewhite;
  }
  .footer-logo {
    width: 145px;
  }
  .footer-pera {
    font-size: 16px;
    line-height: 25px;
  }
  .item-wrap {
    width: 90%;
  }
  .item-wrap-h1 {
    font-size: 15px;
  }
  .footer-item {
    font-size: 18px;
    line-height: 35px;
  }
  .bottom {
    height: 50px;
    padding: 0px 40px;
  }
  .copyRight {
    font-size: 15px;
  }
  .bottom-img {
    width: 300px;
  }
}

/* Editor */
.editorNav {
  align-items: center;
  background-size: 100% 100%;
  display: flex;
  flex-direction: column;
  gap: 11px;
  min-height: 60px;
  width: 100%;
}
.navButtonContainer {
  align-items: center;
  justify-content: space-between;
  display: flex;
  margin-top: 12px;
  min-width: 95%;
}
.item {
  align-items: flex-start;
  background-color: #fbfafc;
  border: 1px solid;
  border-color: #e2e2ea;
  border-radius: 4px;
  display: flex;
  height: 36px;
  min-width: 36px;
  padding: 7px;
  cursor: pointer;
}
.item2 {
  align-items: flex-start;
  background-color: #fbfafc;
  border: 1px solid;
  border-color: #e2e2ea;
  border-radius: 4px;
  display: flex;
  height: 36px;
  margin-left: 12px;
  min-width: 36px;
  opacity: 0.5;
  padding: 7px;
  cursor: pointer;
}
.navLeftBtnGroup{
  display: flex;
}
.navMiddleBtnGroup {
  align-items: center;
  justify-content: space-between;
  background-color: #FAFAFB;
  border: 1px solid;
  border-color: #e2e2ea;
  border-radius: 4px;
  display: flex;
  height: 36px;
  margin-right: 13%;
  padding: 3px;
  min-width: 119px;
}
.navRightBtnGroup{
  display: flex;
}

.editorResponsiveBtn {
  background-color: transparent;
  width: 35px;
  height: 30px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.editorResponsiveBtn svg{
  color: #504F54;
  width: 18px;
  opacity: 0.5;
}

.editorResponsiveBtn.active{
  background: rgba(0, 98, 255, 0.1);
  border-radius: 2px;
}

.editorResponsiveBtn.active svg{
  color: #0062FF;
  opacity: 1;

}

.navDivider {
  background-color: #e2e2ea;
  border: 0px none;
  height: 1px;
  width: 100%;
}

.dropdownContainer {
  margin: 8px 0 0;
  padding: 0;
  align-items: flex-start;
  display: flex;
  gap: 10px;
  height: 36px;
  width: 100%;
  cursor: pointer;
}

.zoom.dropdownContainer {
  margin: 0;
  max-width: 79px;
  padding: 0px 7px;
}

.dropdownControls {
  height: 36px;
  background: #fbfafc;
  border-radius: 4px;
  font-size: 20px;
  width: 100%;
  padding: 0 5px;
  font-weight: 500;
  border: 1px solid #e2e2ea;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.175px;
  color: #2e2c34;
}
.zoom .dropdownControls {
  width: 79px;
}

.fullScreenContainer {
  align-items: flex-start;
  background-color: #fbfafc;
  border: 1px solid;
  border-color: #e2e2ea;
  border-radius: 4px;
  display: flex;
  height: 36px;
  margin-left: 16px;
  min-width: 36px;
  padding: 7px;
}
.editorInterface {
  display: flex;
  align-items: flex-start;
  border-radius: 10px;
  height: 1109px;
}
.editorBody {
  height: 100%;
  width: 100%;
  background-color: rgb(250, 250, 251) !important;
}
/* Editor Sidebar */
.sideBarEditor {
  align-items: center;
  border-left: 1px solid;
  border-color: #e2e2ea;
  background-size: 100% 100%;
  flex-direction: column;
  min-height: 1050px;
  padding: 24px 20px;
  width: 350px;
}
.sideBarTitle {
  align-items: flex-start;
  border: 1px none;
  display: flex;
  gap: 152px;
  width: 252px;
}
.sidebarTitleText {
  color: #2e2c34;
  font-family: 'Manrope', Helvetica;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0.17px;
  line-height: 18px;
  margin-top: 1px;
  margin-left: 4px;
  min-width: 76px;
  white-space: nowrap;
}
.dropDownCarrot {
  height: 24px;
  width: 24px;
  cursor: pointer;
}
.sideBarTextColorHeader {
  letter-spacing: 0.5px;
  line-height: 18px;
  margin-top: 24px;
  text-transform: uppercase;
  font-size: 12px;
  color: #696974;
  font-weight: 600;
}
.toolIcon {
  height: 15px !important;
  width: 15px !important;
  padding-bottom: 3px;
}
.colorTools {
  align-items: flex-start;
  display: flex;
  gap: 14px;
  height: 62px;
  margin-top: 8px;
  min-width: 252px;
}
.hexArea {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 8px;
  min-height: 62px;
  width: 92px;
}
.textColorTitle {
  font-family: 'Manrope', Helvetica;
  color: #696974;
  font-size: 12px;
}
.hex-container {
  width: 90px;
  padding: 6px 10px 6px 6px;
  align-items: center;
  background-color: #ffffff;
  border: 1px solid;
  border-color: #e2e2ea;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
}

.inputReset {
  padding: 0;
  margin: 0;
  border: none;
  border-radius: 0;
  background-color: transparent;
  font-size: 12px;
  font-family: inherit;
  color: inherit;
}

.hex-input {
  max-width: 42px;
}

.hex-preview {
  position: relative;
  width: 18px;
  height: 24px;
  background: #e87443;
  border-radius: 4px;
}

.hex-preview::after {
  content: '';
  width: 1px;
  height: 38px;
  position: absolute;
  top: -7px;
  right: -7px;
  background-color: #e2e2ea;
}

.rgba {
  width:100%;
  display: flex;
  flex-direction: column;
  grid-gap: 8px;
  gap: 8px;
}

.rgba .textColorTitle {
  letter-spacing: 28px;
  margin: 0 12px;
  margin-right: -28px;
  text-align: left;
}

.rgbaValue {
  width: 146px;
  height: 38px;
  border: 1px solid #e2e2ea;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.rgba-input {
  width: 20px;
  height: 18px;
}

.rgba-item {
  position: relative;
}

.rgba-item::after {
  content: '';
  width: 1px;
  height: 38px;
  position: absolute;
  top: -7px;
  right: -7px;
  background-color: #e2e2ea;
}

.rgba-item:last-child:after {
  display: none;
}

.textClass {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.addClass {
  margin-top: 24px;
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
  color: #0062ff;
  cursor: pointer;
}

.fontWeightSize {
  display: flex;
  gap: 12px;
}

.fontWeight {
  width: 60%;
}

.fontSize {
  width: 40%;
}

.spacingLineHeight {
  display: flex;
  gap: 16px;
}

.spacingLineHeightContainer {
  width: 50%;
}

.textInput {
  width: 100%;
  margin-top: 8px;
  padding: 11px 16px;
  border: 1px solid #e2e2ea;
  border-radius: 4px;
  font-size: 14px;
}

.four-buttons-container {
  margin-top: 8px;
  padding: 5px;
  border: 1px solid #e2e2ea;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  gap: 5px;
}

.four-buttons-item {
  width: 100%;
  border: none;
  background-color: transparent;
  max-width: 53px;
  padding: 7px 0;
  border-radius: 2px;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: all ease-in-out .2s;
}

.four-buttons-item:hover{
  box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12);
  background-color: #d5d5d5;
}

.four-buttons-item svg {
  margin: 0 auto;
}

.four-buttons-item svg path{
fill:#696974;
}


.four-buttons-item.active {
  background-color: #0062ff;
 
}

.four-buttons-item.active svg path {
  fill: #ffffff;
 
}

.mediaTools .sideBarTextColorHeader, .additional .sideBarTextColorHeader{
  margin-bottom: 12px;
  font-size: 14px;
  letter-spacing: 0.175px;
  text-transform: none;
  color: #2e2c34;
}

.mediaTools-container,
.additional-container {
  display: flex;
  flex-wrap: wrap;
  gap: 17px;
}

.tools-button {
  width: 50px;
  height: 40px;
  background: #fbfafc;
  border: 1px solid #e2e2ea;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tools-button:hover {
  border-color: #0062ff;
}

.tools-button:hover svg path {
  fill: #0062ff;
}


input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}