.Intuitive-wrap {
  width: 48%;
}
.section {
  padding-top: 100px;
}
.section-head {
  font-size: 46px;
  font-style: normal;
  font-weight: 700;
  line-height: 63px;
  letter-spacing: 0.10000000149011612px;
  text-align: center;
}
.Intuitive-wrap p {
  font-family: 'inter', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 30px;
  text-align: center;
  color: #696974;
}

.side-image-alignment:before {
  content: '';
  width: 580px;
  height: 45%;
  position: absolute;
  top: -120px;
  background: url(../../../images/marketing/featureImages/designVector.svg);
  background-repeat: no-repeat;
  z-index: 100;
  left: 250px;
}
.side-image-reverse-alignment:before {
  content: '';
  width: 580px;
  height: 45%;
  position: absolute;
  transform: scaleX(-1);
  top: -120px;
  background: url(../../../images/marketing/featureImages/designVector.svg);
  background-repeat: no-repeat;
  z-index: 100;
  left: 250px;
}

.noDrag:before {
  content: '';
  width: 32px;
  height: 32px;
  position: absolute;
  top: 10px;
  background: url(../../../images/marketing/webBuilder/noDrag.png);
  z-index: 100;
  left: -40px;
}

.click-Edit:before {
  content: '';
  width: 32px;
  height: 32px;
  position: absolute;
  top: 10px;
  background: url(../../../images/marketing/webBuilder/pointer.png);
  z-index: 100;
  left: -40px;
}

.border-btm {
  border-bottom: 1px solid #e2e2ea;
  padding-bottom: 130px;
}

.see-link-wrap {
  width: auto;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 25px;
  letter-spacing: 0.10000000149011612px;
}
.see-link {
  margin: 0px 5px 0px 27px;
}
.imgs-wrap {
  width: 100%;
}
.template-img {
  height: 299px;
  width: 310px;
}
.circle-1 {
  position: absolute;
  top: 46px;
  left: 295px;
  width: 60px;
  height: 60px;
}
.circle-2 {
  position: absolute;
  top: 220px;
  left: 193px;
  width: 120px;
  height: 120px;
}
.circle-3 {
  position: absolute;
  top: 235px;
  right: 5%;
  width: 230px;
  height: 230px;
}
.circle-4 {
  position: absolute;
  top: 206px;
  right: 21.5%;
}

@media (max-width: 767.98px) {
  .side-image-alignment:before {
    width: 0px;
    height: 0px;
  }
  .side-image-reverse-alignment:before {
    width: 0px;
    height: 0px;
  }
  .host-section-2 {
    width: 80%;
  }
  .blue-section {
    flex-direction: column;
    padding: 20px;
    justify-content: center;
    align-items: center;
    width: 80%;
  }
  .blue-section h1 {
    font-size: 20px;
    margin-bottom: 20px;
  }
  .blue-section p {
    font-size: 12px;
    margin-bottom: 20px;
  }
  .circle-1 {
    display: none;
  }
  .circle-2 {
    display: none;
  }
  .circle-3 {
    display: none;
  }
  .circle-4 {
    display: none;
  }
  .see-link-wrap {
    flex-direction: column;
  }
}
