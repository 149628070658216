.section-1 {
  padding-bottom: 64px;
}
.host-section-2 {
  margin-top: 100px;
}
.cart-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.cart-text h1 {
  font-size: 42px;
  margin-bottom: 30px;
  position: relative;
}

.cart-text p {
  font-size: 18px;
  margin-bottom: 20px;
  color: #696974;
  max-width: 400px;
}

.cart-text a {
  color: blue;
  font-size: 18px;
  font-weight: 700;
}
.side-image-alignment {
  margin-left: -30px;
  padding-left: -15px;
  position: relative;
}

.blue-section {
  display: flex;
  justify-content: space-evenly;
  margin-top: 100px;
  background: #0062ff;
  padding: 80px 0px;
  color: #fff;
  align-items: center;
  border-radius: 10px;
}
.blue-section p {
  max-width: 500px;
}
.blue-section button {
  font-size: 15px;
  color: #0062ff;
  background-color: #fff;
  border: none;
}

.side-image-alignment:before {
  content: '';
  width: 580px;
  height: 45%;
  position: absolute;
  top: -120px;
  background: url(../../../images/marketing/featureImages/designVector.svg);
  background-repeat: no-repeat;
  z-index: 100;
  left: 250px;
}
.side-image-reverse-alignment:before {
  content: '';
  width: 580px;
  height: 45%;
  position: absolute;
  transform: scaleX(-1);
  top: -120px;
  background: url(../../../images/marketing/featureImages/designVector.svg);
  background-repeat: no-repeat;
  z-index: 100;
  left: 250px;
}

.img-text {
  color: #e87443;
  font-size: 16px;
  line-height: 12px;
  font-weight: bold;
  font-family: 'Manrope';
}
.img-text img {
  margin-right: 10px;
}

.circle-1 {
  position: absolute;
  top: 46px;
  left: 295px;
  width: 60px;
  height: 60px;
}
.circle-2 {
  position: absolute;
  top: 220px;
  left: 193px;
  width: 120px;
  height: 120px;
}
.circle-3 {
  position: absolute;
  top: 235px;
  right: 5%;
  width: 230px;
  height: 230px;
}
.circle-4 {
  position: absolute;
  top: 206px;
  right: 21.5%;
}

@media (max-width: 767.98px) {
  .side-image-alignment:before {
    width: 0px;
    height: 0px;
  }
  .side-image-reverse-alignment:before {
    width: 0px;
    height: 0px;
  }
  .host-section-2 {
    width: 80%;
  }
  .blue-section {
    flex-direction: column;
    padding: 20px;
    justify-content: center;
    align-items: center;
    width: 80%;
  }
  .blue-section h1 {
    font-size: 20px;
    margin-bottom: 20px;
  }
  .blue-section p {
    font-size: 12px;
    margin-bottom: 20px;
  }
  .host-section-2 .cart-text {
    margin-top: 30px;
  }
  .img-wrapper {
    margin-top: 30px;
  }
  /* .img-text img{
        width: 85%;
    } */
}
