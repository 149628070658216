:root {
  --black: #000000;
  --blue: #0062ff;
  --grayscaleblack-1: #171725;
  --grayscaleblack-2: #44444f;
  --grayscaleblack-3: #696974;
  --grayscaleblack-4: #92929d;
  --grayscaleblack-7: #e2e2ea;
  --grayscaleblack-8: #f1f1f5;
  --grayscaleblack-9: #fafafb;
  --green: #3cd598;
  --main-brown: #e77343;
  --mirage: #171625;
  --red: #fc5959;
  --white: #ffffff;

  --font-size-l: 15px;
  --font-size-m: 14px;
  --font-size-s: 11px;
  --font-size-xl: 16px;
  --font-size-xxl: 20px;
  --font-size-xxxl: 24px;
  --font-size-xxxxl: 28px;

  --font-family-inter: "Inter", Helvetica;
  --font-family-inter-semibold: "Inter-SemiBold", Helvetica;
  --font-family-manrope: "Manrope", Helvetica;
  --font-family-roboto-regular: "Roboto-Regular", Helvetica;
}
.roboto-regular-normal-mountain-mist-14px {
  color: var(--grayscaleblack-4);
  font-family: var(--font-family-roboto-regular);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 400;
}

.hiddenButton {
  background: var(--blue);
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;

}

.hiddenButton-alt {
  text-transform: capitalize !important;
  color: white; 
  width: 150px;
  height: 50px;
}

.button-label-wrapper {
  display: flex;
  flex-direction: row;
  gap: 4px;
  align-items: center;
}



.disabledButton {
  border: 1px solid black;
  color: black;
  border: none;
  width: 154px;
  height: 44px;
  padding: 10px 10px;
  font: inherit;
  font-weight: bold;
  text-transform: none;
}

.over-container-1 {
  height: 290px;
  margin-left: 6.0px;
  margin-top: 19px;
  position: relative;
  width: 956px;
}

.overlap-group14 {
  height: 290px;
  left: 0;
  position: absolute;
  top: 0;
  width: 956px;
}

.overview-4 {
  color: var(--mirage);
  font-family: var(--font-family-manrope);
  font-size: var(--font-size-xxl);
  font-weight: 600;
  left: 0;
  letter-spacing: 0.10px;
  line-height: normal;
  position: absolute;
  top: 44px;
}

.domainResultsTitle {
  /* padding-left: 19% !important; */
  padding-top: 10px;
  padding-bottom: 40px;
  max-width: 932px;
  margin: 0 auto;
  font-family: var(--font-family-manrope);
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 30px;
  letter-spacing: 0.3px;
  color: #171725;  
}

.domain-results-title-section {
  max-width: 932px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 10px;
  font-family: var(--font-family-manrope);
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  letter-spacing: 0.3px;
  color: #171725;  
  padding-bottom: 20px;
}


.available-domain-results-tag {
  display: flex;
  flex-direction: column;
  justify-content: center; 
  font-size: 16px;
  color: gray; 
}

.inter-normal-nevada-14px {
  color: var(--grayscaleblack-3);
  font-family: var(--font-family-inter);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 400;
}

.inter-normal-mirage-15px {
  color: var(--mirage);
  font-family: var(--font-family-inter);
  font-size: var(--font-size-l);
  font-style: normal;
  font-weight: 400;
}

.manrope-medium-mirage-14px {
  color: var(--grayscaleblack-1);
  font-family: var(--font-family-manrope);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 500;
}

.inter-normal-gravel-14px {
  color: var(--grayscaleblack-2);
  font-family: var(--font-family-inter);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 400;
}

.manrope-bold-mirage-20px {
  color: var(--mirage);
  font-family: var(--font-family-manrope);
  font-size: var(--font-size-xxl);
  font-style: normal;
  font-weight: 700;
}

.manrope-bold-mountain-mist-11px {
  color: var(--grayscaleblack-4);
  font-family: var(--font-family-manrope);
  font-size: var(--font-size-s);
  font-style: normal;
  font-weight: 700;
}

.inter-semi-bold-gravel-14px {
  color: var(--grayscaleblack-2);
  font-family: var(--font-family-inter-semibold);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 600;
}

.manrope-medium-white-14px {
  font-family: var(--font-family-manrope);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 500;
}

.manrope-medium-mountain-mist-14px {
  color: var(--grayscaleblack-4);
  font-family: var(--font-family-manrope);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 500;
}

.manrope-medium-mirage-14px-2 {
  color: var(--mirage);
  font-family: var(--font-family-manrope);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 500;
}

.inter-medium-blue-ribbon-14px {
  color: var(--blue);
  font-family: var(--font-family-inter);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 500;
}

.manrope-semi-bold-mirage-24px {
  color: var(--grayscaleblack-1);
  font-family: var(--font-family-manrope);
  font-size: var(--font-size-xxxl);
  font-style: normal;
  font-weight: 600;
}

.manrope-semi-bold-blue-ribbon-24px {
  color: var(--blue);
  font-family: var(--font-family-manrope);
  font-size: var(--font-size-xxxl);
  font-style: normal;
  font-weight: 600;
}

.manrope-bold-blue-ribbon-15px {
  color: var(--blue);
  font-family: var(--font-family-manrope);
  font-size: var(--font-size-l);
  font-style: normal;
  font-weight: 700;
}

.manrope-bold-alabaster-15px {
  color: var(--grayscaleblack-9);
  font-family: var(--font-family-manrope);
  font-size: var(--font-size-l);
  font-style: normal;
  font-weight: 700;
}

.manrope-semi-bold-mountain-mist-16px {
  color: var(--grayscaleblack-4);
  font-family: var(--font-family-manrope);
  font-size: var(--font-size-xl);
  font-style: normal;
  font-weight: 600;
}

.inter-medium-mirage-16px {
  color: var(--grayscaleblack-1);
  font-family: var(--font-family-inter);
  font-size: var(--font-size-xl);
  font-style: normal;
  font-weight: 500;
}



/* screen - 002-register-domain */

.x002-register-domain {
  align-items: flex-start;
  border: 1px none;
  display: flex;
}

.x002-register-domain .overlap-group12 {
  height: 1347px;
  position: relative;
}

.x002-register-domain .overlap-group-1 {
  align-items: center;
  background-color: var(--main-brown);
  border-radius: 4px;
  display: flex;
  gap: 8px;
  height: 44px;
  left: 1407px;
  min-width: 154px;
  padding: 0 17px;
  position: absolute;
  top: 105px;
}

.x002-register-domain .file_upload {
  height: 20px;
  width: 20px;
}

.x002-register-domain .text-1 {
  letter-spacing: 0.10px;
  line-height: normal;
  min-height: 20px;
  min-width: 90px;
}

.x002-register-domain .overview-4 {
  color: var(--mirage);
  font-family: var(--font-family-manrope);
  font-size: var(--font-size-xxxxl);
  font-weight: 700;
  left: 290px;
  letter-spacing: 0.10px;
  line-height: 36px;
  position: absolute;
  top: 108px;
  white-space: nowrap;
}

.x002-register-domain .overview-5 {
  color: var(--mirage);
  font-family: var(--font-family-manrope);
  font-size: 22px;
  font-weight: 600;
  left: 290px;
  letter-spacing: 0.10px;
  line-height: normal;
  position: absolute;
  top: 401px;
}

.x002-register-domain .overview-6 {
  color: var(--grayscaleblack-4);
  font-family: var(--font-family-manrope);
  font-size: 18px;
  font-weight: 500;
  left: 499px;
  letter-spacing: 0.10px;
  line-height: normal;
  position: absolute;
  top: 404px;
}

.x002-register-domain .website-visits {
  align-items: center;
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 14px 32px #44444f0a;
  display: flex;
  height: 144px;
  justify-content: flex-end;
  left: 290px;
  min-width: 1270px;
  padding: 27px 31px;
  position: absolute;
  top: 178px;
}

.x002-register-domain .item-1 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 9px;
  min-height: 88px;
  width: 352px;
}

.x002-register-domain .overview {
  letter-spacing: 0.10px;
  line-height: normal;
  min-height: 22px;
}

.x002-register-domain .overlap-group-2 {
  align-items: flex-start;
  background-color: var(--white);
  border: 1px solid;
  border-color: var(--grayscaleblack-8);
  border-radius: 10px;
  display: flex;
  height: 58px;
  margin-left: -1px;
  min-width: 352px;
  padding: 18px 16px;
}

.x002-register-domain .sales-figures {
  letter-spacing: 0.10px;
  line-height: normal;
  min-height: 19px;
}

.x002-register-domain .item {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 9px;
  margin-left: 28px;
  min-height: 88px;
  width: 222px;
}

.x002-register-domain .overlap-group-3 {
  align-items: flex-start;
  background-color: var(--white);
  border: 1px solid;
  border-color: var(--grayscaleblack-8);
  border-radius: 10px;
  display: flex;
  gap: 88px;
  height: 58px;
  margin-left: -1px;
  min-width: 222px;
  padding: 16px;
}

.x002-register-domain .sales-figures-1 {
  align-self: center;
  letter-spacing: 0.10px;
  line-height: normal;
  margin-bottom: 3.0px;
  min-height: 19px;
  min-width: 76px;
}

.x002-register-domain .ic_-chevron {
  height: 24px;
  width: 24px;
}

.x002-register-domain .overlap-group-4 {
  align-items: flex-start;
  background-color: var(--white);
  border: 1px solid;
  border-color: var(--grayscaleblack-8);
  border-radius: 10px;
  display: flex;
  gap: 96px;
  height: 58px;
  margin-left: -1px;
  min-width: 222px;
  padding: 16px;
}

.x002-register-domain .sales-figures-2 {
  align-self: center;
  letter-spacing: 0.10px;
  line-height: normal;
  margin-bottom: 3.0px;
  min-height: 19px;
  min-width: 68px;
}

.x002-register-domain .ic_-chevron-1 {
  height: 24px;
  width: 24px;
}

.x002-register-domain .overlap-group-5 {
  align-items: center;
  align-self: flex-end;
  background-color: var(--grayscaleblack-1);
  border-radius: 4px;
  display: flex;
  height: 58px;
  justify-content: flex-end;
  margin-left: 171px;
  min-width: 184px;
  padding: 0 31px;
}

.x002-register-domain .text-2 {
  color: var(--grayscaleblack-9);
  font-family: var(--font-family-manrope);
  font-size: var(--font-size-xl);
  font-weight: 800;
  letter-spacing: 0.10px;
  line-height: normal;
  min-height: 22px;
  min-width: 121px;
}

.x002-register-domain .overlap-group1 {
  align-items: flex-start;
  background-color: var(--white);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  left: 1249px;
  min-height: 345px;
  padding: 21px 25px;
  position: absolute;
  top: 451px;
  width: 312px;
}

.x002-register-domain .sales-figures-3 {
  letter-spacing: 0.10px;
  line-height: normal;
  margin-top: 6px;
  min-height: 27px;
}

.x002-register-domain .frame-2 {
  align-items: center;
  border: 1px none;
  display: flex;
  gap: 10px;
  margin-top: 20px;
  width: fit-content;
}

.x002-register-domain .check_box {
  height: 22px;
  min-width: 22px;
}

.x002-register-domain .overview-1 {
  letter-spacing: 0.10px;
  line-height: normal;
  width: fit-content;
}

.x002-register-domain .frame {
  align-items: center;
  border: 1px none;
  display: flex;
  gap: 10px;
  margin-top: 16px;
  width: fit-content;
}

.x002-register-domain .flex-row {
  align-items: flex-start;
  background-color: var(--white);
  border: 1px solid;
  border-color: var(--blue);
  border-radius: 10px;
  display: flex;
  gap: 167px;
  height: 193px;
  min-width: 932px;
  padding: 1px 1.0px;
}

.x002-register-domain .flex-row-alt {
  align-items: flex-start;
  background-color: var(--white);
  border: 1px solid;
  border-color: var(--blue);
  border-radius: 10px;
  display: flex;
  gap: 167px;

  min-width: 932px;
  padding: 1px 1.0px;
}

.x002-register-domain .flex-row-1 {
  align-items: flex-end;
  display: flex;
  gap: 32px;
  height: 167px;
  min-width: 574px;
}

.x002-register-domain .flex-row-1-alt {
  min-width: 574px;
}

.x002-register-domain .flex-col-1 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  min-height: 167px;
  width: 355px;
}

.x002-register-domain .flex-col-1-alt {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 355px;
}

.x002-register-domain .overlap-group-6 {
  align-items: center;
  background-image: url(../../../images/domain/shape@2x.svg);
  background-size: 100% 100%;
  display: flex;
  height: 39px;
  justify-content: flex-end;
  min-width: 231px;
  padding: 0 32.0px;
}

.x002-register-domain .overlap-group-60 {
  align-items: center;
  background-image: url(../../../images/domain/orange_shape@2x.svg);
  background-size: 100% 100%;
  display: flex;
  height: 39px;
  justify-content: flex-end;
  min-width: 231px;
  padding: 0 32.0px;
}

.x002-register-domain .overview-7 {
  color: var(--white);
  font-family: var(--font-family-manrope);
  font-size: var(--font-size-m);
  font-weight: 800;
  letter-spacing: 1.00px;
  line-height: normal;
  min-height: 19px;
  min-width: 167px;
}

.x002-register-domain .overview-8 {
  color: var(--blue);
  font-family: var(--font-family-manrope);
  font-size: var(--font-size-xxxl);
  font-weight: 700;
  letter-spacing: 0.10px;
  line-height: normal;
  margin-left: 32.0px;
  width: 100%;
  margin-top: 21px;
  min-height: 33px;
}


.x002-register-domain .overview-8-alt {
  color: var(--blue);
  font-family: var(--font-family-manrope);
  font-size: var(--font-size-xxxl);
  font-weight: 700;
  letter-spacing: 0.10px;
  line-height: normal;
  margin-left: 32.0px;
  width: 100%;
  margin-top: 5px;
  padding-bottom: 5px;
  min-height: 33px;
}

.x002-register-domain .frame-2-1 {
  align-items: center;
  border: 1px none;
  display: flex;
  gap: 10px;
  margin-left: 32.0px;
  margin-top: 22px;
  width: fit-content;
}

.x002-register-domain .check {
  height: 20px;
  min-width: 20px;
}

.x002-register-domain .frame-3 {
  align-items: center;
  border: 1px none;
  display: flex;
  gap: 10px;
  margin-top: 12px;
  margin-left: 32px;
  width: fit-content;
  padding-right: 45px;
}

.x002-register-domain .frame-container {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 12px;
  min-height: 52px;
  width: 187px;
}

.x002-register-domain .frame-1 {
  align-items: center;
  border: 1px none;
  display: flex;
  gap: 10px;
  width: fit-content;
}

.x002-register-domain .flex-col-2 {
  align-items: flex-start;
  align-self: center;
  display: flex;
  flex-direction: column;
  gap: 31px;
  margin-top: 37.0px;
  min-height: 108px;
  width: 158px;
}

.x002-register-domain .flex-col-2-alt {
  align-items: flex-start;
  align-self: center;
  display: flex;
  flex-direction: column;
  gap: 31px;
  width: 158px;
}

.x002-register-domain .overview-container {
  align-items: center;
  display: flex;
  gap: 16px;
  height: 33px;
  min-width: 157px;
}
.x002-register-domain .overview-container-alt {
  align-items: center;
  display: flex;
  gap: 16px;

  min-width: 157px;
}

.x002-register-domain .overview-9 {
  color: var(--grayscaleblack-3);
  font-family: var(--font-family-manrope);
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0.10px;
  line-height: normal;
  min-height: 25px;
  min-width: 58px;
  text-decoration: line-through;
}

.x002-register-domain .overview-10 {
  color: var(--blue);
  font-family: var(--font-family-manrope);
  font-size: var(--font-size-xxxl);
  font-weight: 700;
  letter-spacing: 0.10px;
  line-height: normal;
  min-height: 33px;
  min-width: 83px;
  text-align: end;
  width: 100%;
}

.x002-register-domain .overview-10-alt {
  color: #171625;
  font-family: var(--font-family-manrope);
  font-size: var(--font-size-xxxl);
  font-weight: 700;
  letter-spacing: 0.10px;
  min-height: 33px;
  min-width: 83px;
  text-align: end;
  width: 100%;
}

.x002-register-domain .premium-layout {
  color: #eda800;
  font-size: 15px;  
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  }

.x002-register-domain .premium-icon-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.x002-register-domain .overlap-group1-1 {
  align-items: center;
  background-color: var(--blue);
  border-radius: 4px;
  display: flex;
  gap: 8px;
  height: 44px;
  margin-left: 4px;
  min-width: 154px;
  padding: 0 22px;
  color: '#f3f3f3'
}

.x002-register-domain .overlap-group1-1-alt {
  align-items: center;
  background-color: var(--blue);
  border-radius: 4px;
  display: flex;
  gap: 8px;
  height: 44px;
  margin-left: 4px;
  min-width: 154px;
  padding-left: 18px;
  color: '#f3f3f3'
}

.x002-register-domain .local_grocery_store {
  color: white;
  height: 20px;
  width: 20px;
}

.x002-register-domain .local_grocery_store_recommended_domain {
  color: #a6a6a6;
  height: 20px;
  /* width: 20px; */
}

.x002-register-domain .text-3 {
  letter-spacing: 0.10px;
  line-height: normal;
  min-height: 20px;
  min-width: 81px;
}

.x002-register-domain .overlap-group3 {
  align-items: flex-end;
  background-color: var(--white);
  border-radius: 10px;
  display: flex;
  height: 141px;
  justify-content: flex-end;
  left: 289px;
  min-width: 932px;
  padding: 24px 32px;
  position: absolute;
  top: 666px;
}

.x002-register-domain .flex-col {
  align-items: flex-start;
  align-self: flex-start;
  display: flex;
  flex-direction: column;
  gap: 27px;
  margin-top: 1px;
  min-height: 80px;
  width: 213px;
}

.x002-register-domain .overview-2 {
  letter-spacing: 0.10px;
  line-height: normal;
  min-height: 33px;
}

.x002-register-domain .frame-4 {
  align-items: center;
  border: 1px none;
  display: flex;
  gap: 10px;
  margin-bottom: 12px;
  margin-left: 32px;
  width: fit-content;
}

.x002-register-domain .over-container {
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-left: 278px;
  min-height: 92px;
  width: 154px;
}

.x002-register-domain .overview-3 {
  letter-spacing: 0.10px;
  line-height: normal;
  margin-right: 3px;
  min-height: 33px;
  min-width: 81px;
}

.x002-register-domain .overlap-group {
  align-items: flex-start;
  background-color: var(--white);
  border: 1px solid;
  border-color: var(--blue);
  border-radius: 4px;
  display: flex;
  gap: 8px;
  height: 44px;
  min-width: 154px;
  padding: 11px 21px;
}

.x002-register-domain .local_grocery_store-1 {
  height: 20px;
  width: 20px;
}

.x002-register-domain .text {
  letter-spacing: 0.10px;
  line-height: normal;
  min-height: 20px;
}

.x002-register-domain .overlap-group4 {
  align-items: flex-end;
  background-color: var(--white);
  border-radius: 10px;
  display: flex;
  height: 141px;
  justify-content: flex-end;
  left: 289px;
  min-width: 932px;
  padding: 24px 32px;
  position: absolute;
  top: 825px;
}

.x002-register-domain .local_grocery_store-2 {
  height: 20px;
  width: 20px;
}

.x002-register-domain .overlap-group5 {
  align-items: flex-end;
  background-color: var(--white);
  border-radius: 10px;
  display: flex;
  height: 141px;
  justify-content: flex-end;
  left: 289px;
  min-width: 932px;
  padding: 24px 32px;
  position: absolute;
  top: 984px;
}

.x002-register-domain .local_grocery_store-3 {
  height: 20px;
  width: 20px;
}

.x002-register-domain .overlap-group6 {
  align-items: flex-end;
  background-color: var(--white);
  border-radius: 10px;
  display: flex;
  height: 141px;
  justify-content: flex-end;
  left: 289px;
  min-width: 932px;
  padding: 24px 32px;
  position: absolute;
  top: 1143px;
}

.x002-register-domain .local_grocery_store-4 {
  height: 20px;
  width: 20px;
}

.x002-register-domain .vector-2 {
  height: 1px;
  left: 290px;
  object-fit: cover;
  position: absolute;
  top: 362px;
  width: 1270px;
}

.x002-register-domain .overlap-group11 {
  height: 1347px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1600px;
}

.x002-register-domain .sidebar {
  align-items: flex-start;
  background-color: var(--white);
  display: flex;
  flex-direction: column;
  gap: 24px;
  left: 0;
  min-height: 1347px;
  padding: 16px 0;
  position: absolute;
  top: 0;
  width: 250px;
}

.x002-register-domain .image-1 {
  height: 46px;
  margin-left: 25px;
  object-fit: cover;
  width: 119px;
}

.x002-register-domain .main {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 14px;
  justify-content: center;
  min-height: 536px;
  width: 249px;
}

.x002-register-domain .overlap-group-container {
  height: 470px;
  margin-left: -1px;
  position: relative;
  width: 251px;
}

.x002-register-domain .overlap-group-7 {
  align-items: center;
  background-color: var(--white);
  display: flex;
  gap: 20px;
  height: 54px;
  left: 0;
  min-width: 251px;
  padding: 0 21px;
  position: absolute;
  top: 0;
}

.x002-register-domain .dashboard {
  height: 24px;
  width: 24px;
}

.x002-register-domain .menu-active {
  letter-spacing: 0.10px;
  line-height: normal;
  margin-bottom: 1.0px;
  min-height: 19px;
  min-width: 73px;
}

.x002-register-domain .overlap-group1-2 {
  align-items: center;
  background-color: var(--white);
  display: flex;
  gap: 20px;
  height: 54px;
  left: 0;
  min-width: 251px;
  padding: 0 21px;
  position: absolute;
  top: 52px;
}

.x002-register-domain .web {
  height: 24px;
  width: 24px;
}

.x002-register-domain .menu-active-1 {
  letter-spacing: 0.10px;
  line-height: normal;
  margin-bottom: 1.0px;
  min-height: 19px;
  min-width: 55px;
}

.x002-register-domain .overlap-group2 {
  align-items: center;
  background-color: var(--white);
  display: flex;
  gap: 20px;
  height: 54px;
  left: 0;
  min-width: 251px;
  padding: 0 21px;
  position: absolute;
  top: 104px;
}

.x002-register-domain .assessment {
  height: 24px;
  width: 24px;
}

.x002-register-domain .menu-active-2 {
  letter-spacing: 0.10px;
  line-height: normal;
  margin-bottom: 1.0px;
  min-height: 19px;
  min-width: 63px;
}

.x002-register-domain .overlap-group3-1 {
  align-items: center;
  background-color: var(--white);
  display: flex;
  gap: 5px;
  height: 54px;
  left: 0;
  min-width: 251px;
  position: absolute;
  top: 156px;
}

.x002-register-domain .selector {
  background-color: var(--main-brown);
  border: 0px none;
  border-radius: 0px 100px 100px 0px;
  height: 34px;
  width: 5px;
}

.x002-register-domain .overlap-group-8 {
  align-items: center;
  background-color: #e773430f;
  border-radius: 8px;
  display: flex;
  gap: 20px;
  height: 44px;
  min-width: 231px;
  padding: 0 11px;
}

.x002-register-domain .rss_feed {
  height: 24px;
  width: 24px;
}

.x002-register-domain .menu-active-3 {
  color: var(--main-brown);
  font-family: var(--font-family-manrope);
  font-size: var(--font-size-m);
  font-weight: 800;
  letter-spacing: 0.10px;
  line-height: normal;
  margin-bottom: 1.0px;
  min-height: 19px;
  min-width: 116px;
}

.x002-register-domain .overlap-group4-1 {
  align-items: center;
  background-color: var(--white);
  display: flex;
  gap: 20px;
  height: 54px;
  left: 0;
  min-width: 251px;
  padding: 0 21px;
  position: absolute;
  top: 208px;
}

.x002-register-domain .storage {
  height: 24px;
  width: 24px;
}

.x002-register-domain .menu-active-4 {
  letter-spacing: 0.10px;
  line-height: normal;
  margin-bottom: 1.0px;
  min-height: 19px;
  min-width: 53px;
}

.x002-register-domain .overlap-group5-1 {
  align-items: center;
  background-color: var(--white);
  display: flex;
  gap: 20px;
  height: 54px;
  left: 0;
  min-width: 251px;
  padding: 0 21px;
  position: absolute;
  top: 260px;
}

.x002-register-domain .traffic {
  height: 24px;
  width: 24px;
}

.x002-register-domain .menu-active-5 {
  letter-spacing: 0.10px;
  line-height: normal;
  margin-bottom: 1.0px;
  min-height: 19px;
  min-width: 85px;
}

.x002-register-domain .overlap-group6-1 {
  align-items: center;
  background-color: var(--white);
  display: flex;
  gap: 21px;
  height: 54px;
  left: 0;
  min-width: 251px;
  padding: 0 22px;
  position: absolute;
  top: 312px;
}

.x002-register-domain .email {
  height: 22px;
  width: 22px;
}

.x002-register-domain .menu-active-6 {
  letter-spacing: 0.10px;
  line-height: normal;
  margin-bottom: 1.0px;
  min-height: 19px;
  min-width: 36px;
}

.x002-register-domain .overlap-group7 {
  align-items: center;
  background-color: var(--white);
  display: flex;
  gap: 20px;
  height: 54px;
  left: 0;
  min-width: 251px;
  padding: 0 21px;
  position: absolute;
  top: 364px;
}

.x002-register-domain .local_grocery_store-5 {
  height: 24px;
  width: 24px;
}

.x002-register-domain .menu-active-7 {
  letter-spacing: 0.10px;
  line-height: normal;
  margin-bottom: 1.0px;
  min-height: 19px;
  min-width: 37px;
}

.x002-register-domain .overlap-group9 {
  align-items: center;
  background-color: var(--white);
  display: flex;
  gap: 20px;
  height: 54px;
  left: 0;
  min-width: 251px;
  padding: 0 21px;
  position: absolute;
  top: 416px;
}

.x002-register-domain .account_circle {
  height: 24px;
  width: 24px;
}

.x002-register-domain .menu-active-8 {
  letter-spacing: 0.10px;
  line-height: normal;
  margin-bottom: 1.0px;
  min-height: 19px;
  min-width: 57px;
}

.x002-register-domain .overlap-group8 {
  align-items: center;
  background-color: var(--white);
  display: flex;
  gap: 20px;
  height: 54px;
  margin-left: -1px;
  min-width: 251px;
  padding: 0 21px;
}

.x002-register-domain .logout {
  height: 24px;
  width: 24px;
}

.x002-register-domain .menu-active-9 {
  color: #fc5a5a;
  font-family: var(--font-family-manrope);
  font-size: var(--font-size-m);
  font-weight: 600;
  letter-spacing: 0.10px;
  line-height: normal;
  margin-bottom: 1.0px;
  min-height: 19px;
  min-width: 48px;
}

.x002-register-domain .toolbar {
  align-items: center;
  background-color: var(--white);
  display: flex;
  height: 70px;
  left: 249px;
  min-width: 1351px;
  position: absolute;
  top: 0;
}

.x002-register-domain .separator {
  align-self: flex-start;
  background-color: var(--grayscaleblack-9);
  border: 0px none;
  height: 72px;
  margin-left: -1px;
  margin-top: -1px;
  width: 3px;
}

.x002-register-domain .btn_-find2 {
  align-items: center;
  display: flex;
  gap: 10px;
  height: 38px;
  margin-left: 19px;
  min-width: 280px;
}

.x002-register-domain .ic_-plus {
  align-items: flex-start;
  border: 1px none;
  display: flex;
  min-width: 20px;
}

.x002-register-domain .ic_-search {
  align-items: flex-start;
  background-image: url(../../../images/domain/path@2x.png);
  background-size: 100% 100%;
  display: flex;
  height: 20px;
  justify-content: flex-end;
  margin-left: -2895px;
  margin-top: -1460px;
  min-width: 20px;
  padding: 1.7px 1.6px;
}

.x002-register-domain .combined-shape {
  height: 16px;
  width: 17px;
}

.x002-register-domain .button {
  color: var(--grayscaleblack-4);
  font-family: var(--font-family-inter);
  font-size: var(--font-size-m);
  font-weight: 400;
  letter-spacing: 0.10px;
  line-height: normal;
  margin-top: 1.0px;
  min-height: 17px;
  min-width: 133px;
}

.x002-register-domain .notification-container {
  height: 28px;
  margin-bottom: 4.0px;
  margin-left: 793px;
  position: relative;
  width: 29px;
}

.x002-register-domain .notifications {
  height: 24px;
  left: 0;
  position: absolute;
  top: 4px;
  width: 24px;
}

.x002-register-domain .notification-sign {
  background-color: var(--red);
  border: 0px none;
  border-radius: 12px;
  height: 12px;
  left: 17px;
  position: absolute;
  top: 0;
  width: 12px;
}

.x002-register-domain .user {
  align-items: center;
  display: flex;
  margin-left: 29px;
  min-width: 161px;
}

.x002-register-domain .profile {
  height: 32px;
  object-fit: cover;
  width: 32px;
}

.x002-register-domain .austin-robertson {
  color: var(--mirage);
  font-family: var(--font-family-manrope);
  font-size: var(--font-size-m);
  font-weight: 600;
  letter-spacing: 0.10px;
  line-height: normal;
  margin-bottom: 1.0px;
  margin-left: 10px;
  min-height: 19px;
  min-width: 95px;
}

.x002-register-domain .ic_-dropdown {
  height: 16px;
  margin-left: 6px;
  width: 16px;
}
