.support-circle-1 {
  position: absolute;
  top: 46px;
  left: 315px;
  width: 80px;
  height: 80px;
}

.support-circle-2 {
  position: absolute;
  height: 160px;
  width: 160px;
  left: 173px;
  top: 280px;
}

.support-circle-4 {
  position: absolute;
  height: 50px;
  width: 50px;
  left: 60%;
  top: 156px;
  border-radius: 0px;
}

.support-circle-3 {
  position: absolute;
  top: 496px;
  right: 0%;
  border-radius: 0px;
}

.support-section {
  padding: 120px 215px 0px 215px;
}

.wrapper {
  margin-bottom: 100px;
}

.wrap-h2 {
  font-weight: bold;
  font-size: 40px;
  line-height: 55px;
  letter-spacing: 0.1px;
  color: #171725;
  margin-bottom: 10px;
}

.wrap-pera {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 30px;
  color: #696974;
}

.contect-Us {
  margin-top: 100px;
}

.contect-Us h2 {
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  line-height: 55px;
  letter-spacing: 0.1px;
  color: #171725;
}

.contect-Us p {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 30px;
  color: #696974;
  margin-top: 10px;
  margin-bottom: 57px;
}

.contect-link {
  border-bottom: 1px solid #ececec;
  padding: 17px 0px;
}

.contect-Us a {
  font-family: ' Inter', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 30px;
  color: #0062ff;
  margin-right: 6px;
}

#main {
  margin: 50px 0;
}

.card-body {
  padding: 0px 1.25rem 1.25rem 1.25rem;
}

#main #faq .card {
  margin-bottom: 12px;
  border: 0;
  border-bottom: 1px solid #ececec;
}

#main #faq .card .card-header {
  border: 0;
  -webkit-box-shadow: 0 0 20px 0 rgba(213, 213, 213, 0.5);
  box-shadow: 0 0 20px 0 rgba(213, 213, 213, 0.5);
  border-radius: 2px;
  padding: 0;
}

#main #faq .card .card-header .btn-header-link {
  color: #fff;
  display: block;
  text-align: left;
  background: #fafafb;
  color: #222;
  padding: 20px;
}

#main #faq .card .card-header .btn-header-link:after {
  content: ' ';
  background-image: url(../../../images/marketing/dropDown.svg);
  font-weight: 900;
  float: right;
  color: blue;
}

#main #faq .card .card-header .btn-header-link.collapsed {
  background: #ffffff;
}

#main #faq .card .card-header .btn-header-link.collapsed:after {
  content: ' ';
  background-image: url(../../../images/marketing/dropDown.svg);
}

#main #faq .card .collapsing {
  background: #fafafb;
  line-height: 30px;
}

#main #faq .card .collapse {
  border: 0;
}

#main #faq .card .collapse.show {
  background: #fafafb;
  line-height: 30px;
  color: #222;
}

a {
  text-decoration: none;
  color: black;
}

.support-row {
  margin-left: 0px;
  margin-right: 0px;
  width: 54rem;
  z-index: 0;
}

.support-btn-1 {
  width: 165px;
  margin: 0px 5px 0px 5px;
}

.support-btn-2 {
  width: 200px;
  margin: 0px 5px 0px 5px;
}

.support-section-1 {
  background-color: #f8f8f8;
}
