@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@600&display=swap');

.checkoutWrapper {
  display: flex;
  justify-content: center;
  gap: 30px;
  background: #fafafb;
  padding: 68px 1rem 120px;
}

.checkoutLeftContent {
  background: #ffffff;
  width: 55%;
  border-radius: 10px;
  padding: 32px 24px;
}
.checkoutTitle {
  font-size: 28px;
  font-weight: 700;
  color: #171725;
}
.checkoutSubtitle {
  font-family: 'Poppins', sans-serif;
  font-size: 20px;
  margin-top: 32px;
  font-weight: 600;
  color: #171725;
}

.formBlock {
  display: flex;
  gap: 24px;
  margin-top: 24px;
}
.formWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.formLabel {
  font-size: 12px;
  color: #2e2c34;
}
.formInput {
  padding: 12px 16px;
  border: 1px solid #ebeaed;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 600;
  color: #3f404e;
}
.formInput:focus {
  border: 1px solid #2e2c34;
}
.formInput::placeholder {
  font-weight: 400;
  color: #808080;
}
.formInputError {
  border: 1px solid #dc143c !important;
  border-radius: 4px;
}
.passwordWrapper {
  position: relative;
}

.iconImg {
  position: absolute;
  cursor: pointer;
  right: 12px;
  top: 0;
  bottom: 0;
  margin: auto 0;
}

.cvvText {
  margin-top: 4px;
  font-size: 13px;
  color: #808080;
}

.checkoutRightContent {
  background: #ffffff;
  height: fit-content;
}
.checkoutOrder {
  background: #ffffff;
  padding: 32px 24px;
  height: 654px;
  max-width: 460px;
  width: 100%;
  top: 90px;
  right: 20px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  flex-direction: column;
  color: #171725;
  z-index: 10;
}
.checkoutOrderTitle {
  font-size: 28px;
  font-weight: 700;
  align-self: flex-start;
}
.checkoutOrderTableScroll {
  max-height: 228px;
  overflow-y: auto;
  height: 100%;
}
.checkoutOrderTable {
  width: 100%;
  margin-top: 12px;
  table-layout: fixed;
}
.checkoutOrderTableHead {
  font-size: 11px;
  color: #92929d;
  text-transform: uppercase;
  border-bottom: 1px solid #f1f1f5;
}
.checkoutOrderTableHead::after {
  line-height: 13px;
  content: '.';
  color: white;
  display: block;
}
.checkoutOrderTableContent {
  color: #171725;
  font-size: 14px;
  font-weight: 600;
  border-bottom: 1px solid #f1f1f5;
}
.checkoutOrderTableContent tr {
  height: 52px;
}
.checkoutOrderTableContent:before,
.checkoutOrderTableContent::after {
  line-height: 12px;
  content: '.';
  color: white;
  display: block;
}
.checkoutOrderTableContent tr td {
  padding-top: 14px;
  padding-bottom: 14px;
}
.checkoutOrderPrice {
  display: flex;
  justify-content: center;
  gap: 12px;
}
.checkoutOrderPriceTotal {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 24px;
  color: #171725;
  font-size: 20px;
  font-weight: 600;
  font-family: 'Inter', sans-serif;
}
.checkoutAddDiscount {
  color: #0062ff;
  font-size: 16px;
  font-weight: 700;
  margin-top: 22px;
  align-self: baseline;
}
.checkoutBtn {
  background-color: #0062ff;
  color: #ffffff;
  text-transform: none;
  width: 100%;
  border: none;
  padding: 10px 0px;
  border-radius: 4px;
  margin-top: 30px;
}
.checkoutCardBlock {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  background-color: #fafafa;
  border-radius: 4px;
  margin-top: 30px;
  width: 100%;
  padding: 12px 0;
}
.checkoutCardText {
  color: #171725;
  font-size: 14px;
  font-weight: 700;
}
.checkoutCardWrapper {
  display: flex;
  gap: 12px;
}
.checkoutInformationText {
  display: flex;
  gap: 10px;
  margin-top: 22px;
  color: #696974;
  font-size: 14px;
}

.errorText {
  font-size: 12px;
  margin-top: 8px;
  color: #dc143c;
}
.errorTextTop {
  font-size: 14px;
  color: #dc143c;
}
.errorTextTopBg {
  margin-top: 24px;
  background-color: #f6d5d4;
  padding: 12px;
  border-radius: 4px;
}

.processingPaymentContent {
  display: flex;
  justify-content: center;
  margin: 0 auto;
  padding-top: 100px;
  width: 100%;
  height: 50vh;
  background: #ffffff;
}

.processingPaymentCircle {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 28px;
  font-weight: 600;
}
