.mainPricingHeading {
  font-family: 'Montserrat', sans-serif;
  line-height: 110px;
  margin-top: -350px;
  text-align: center;
  overflow: visible;
  position: relative;
  z-index: 1000;
  width: 100%;
}
.mainPricingHeadingMobile {
  font-family: 'Montserrat', sans-serif;
  box-shadow: rgba(0, 0, 0, 0.06) 0px 4px 12px 0px;
  width: 100%;
  border-radius: 4px;
  padding: 32px;
  background: rgb(255, 255, 255);
  margin-top: 5px;
}
.pricingHeadingAlignMobile h1 {
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
  font-size: 30px;
  padding-bottom: 20px;
  color: #003d66;
}
.pricingSubHeadingMobile {
  font-family: 'Montserrat', sans-serif;
  font-size: 19px;
  padding-bottom: 20px;
  color: #003d66;
}
.mainFeaturesHeading {
  font-family: 'Montserrat', sans-serif;
  padding-top: 70px;
  line-height: 110px;
  margin-top: 5px;
  text-align: center;
  z-index: 1000;
  width: 100%;
}
.mainFeaturesHeadingMobile {
  font-family: 'Montserrat', sans-serif;
  box-shadow: rgba(0, 0, 0, 0.06) 0px 4px 12px 0px;
  width: 100%;
  border-radius: 4px;
  padding: 32px;
  background: rgb(255, 255, 255);
  margin-top: 5px;
}
.mainWebBuilderHeading {
  font-family: 'Montserrat', sans-serif;
  padding-top: 70px;
  line-height: 110px;
  margin-top: 5px;
  text-align: center;
  z-index: 1000;
  width: 100%;
}
.mainWebBuilderHeadingMobile {
  font-family: 'Montserrat', sans-serif;
  box-shadow: rgba(0, 0, 0, 0.06) 0px 4px 12px 0px;
  width: 100%;
  border-radius: 4px;
  padding: 32px;
  background: rgb(255, 255, 255);
  margin-top: 5px;
}
/* Support Page */
.mainSupportHeading {
  font-family: 'Montserrat', sans-serif;
  background-color: #45474e;
  padding-top: 70px;
  line-height: 110px;
  margin-top: 5px;
  text-align: center;
  z-index: 1000;
  width: 100%;
}
.mainSupportHeadingMobile {
  font-family: 'Montserrat', sans-serif;
  box-shadow: rgba(0, 0, 0, 0.06) 0px 4px 12px 0px;
  width: 100%;
  border-radius: 4px;
  padding: 32px;
  background: rgb(255, 255, 255);
  margin-top: 5px;
}
.supportSubNav {
  height: 70px;
  background: #f4f6f9;
  text-align: center;
  font-family: 'Montserrat', sans-serif;
  padding-top: 20px;
  padding-bottom: 98px;
}
.supportSubNav a {
  color: #000;
  font-size: 20px;
}
.supportSubNavLink {
  padding: 20px;
}
.FAQList {
  font-size: 18px;
}
.featuresHeadingAlign {
}
.featuresHeadingAlignMobile h1 {
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
  font-size: 30px;
  padding-bottom: 20px;
  color: #003d66;
}
.webBuilderHeadingAlign {
}
.webBuilderHeadingAlignMobile h1 {
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
  font-size: 30px;
  padding-bottom: 20px;
  color: #003d66;
}
.supportHeadingAlign {
  color: white;
}
.supportHeadingAlignMobile h1 {
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
  font-size: 30px;
  padding-bottom: 20px;
  color: #003d66;
}
.webBuilderSubHeadingMobile {
  font-family: 'Montserrat', sans-serif;
  font-size: 19px;
  padding-bottom: 20px;
  color: #003d66;
}
.featuresSubHeadingMobile {
  font-family: 'Montserrat', sans-serif;
  font-size: 19px;
  padding-bottom: 20px;
  color: #003d66;
}
.supportSubHeading {
  font-family: 'Montserrat', sans-serif;
  font-size: 19px;
}
.supportSubHeadingMobile {
  font-family: 'Montserrat', sans-serif;
  font-size: 19px;
  padding-bottom: 20px;
  color: #003d66;
}
.featuresSubNav {
  height: 70px;
  background: #f4f6f9;
  text-align: center;
  font-family: 'Montserrat', sans-serif;
  padding-top: 20px;
  padding-bottom: 98px;
}
.featuresSubNav a {
  color: #000;
  font-size: 20px;
}
.featuresSubNavLink {
  padding: 20px;
}
.featuresBody {
}
.featuresBodyMobile {
  padding: 32px;
}
.websiteToolbox {
  padding-top: 120px;
  margin-left: 20%;
  margin-right: 20%;
  padding-bottom: 30px;
}
.websiteToolboxMobile {
  padding-top: 30px;
  padding-bottom: 30px;
}
.WTBHeading {
  font-family: 'Montserrat', sans-serif;
  font-size: 35px;
  font-weight: bold;
  padding-bottom: 30px;
}
.WTBHeadingMobile {
  font-family: 'Montserrat', sans-serif;
  font-size: 29px;
  font-weight: bold;
  padding-bottom: 30px;
  color: #003d66;
}
.WTBdescription {
  font-family: 'Montserrat', sans-serif;
  font-size: 20px;
  font-weight: lighter;
  border-bottom: 1px solid black;
  padding-bottom: 20px;
}
.WTBdescriptionMobile {
  font-family: 'Montserrat', sans-serif;
  font-size: 20px;
  font-weight: lighter;
  border-bottom: 1px solid black;
  padding-bottom: 20px;
}
.easyDomainReg {
  padding-top: 120px;
  margin-left: 20%;
  margin-right: 20%;
  padding-bottom: 30px;
}
.easyDomainRegMobile {
  padding-bottom: 30px;
}
.easyDomainRegHeading {
  font-family: 'Montserrat', sans-serif;
  font-size: 35px;
  font-weight: bold;
  padding-bottom: 30px;
}
.easyDomainRegHeadingMobile {
  padding-top: 30px;
  font-family: 'Montserrat', sans-serif;
  font-size: 29px;
  font-weight: bold;
  padding-bottom: 30px;
  color: #003d66;
}
.easyDomainRegDescription {
  font-family: 'Montserrat', sans-serif;
  font-size: 20px;
  font-weight: lighter;
  border-bottom: 1px solid black;
  padding-bottom: 20px;
}
.freeHosting {
  padding-top: 120px;
  margin-left: 20%;
  margin-right: 20%;
  padding-bottom: 30px;
}
.freeHostingMobile {
  padding-bottom: 30px;
}
.freeHostingHeading {
  font-family: 'Montserrat', sans-serif;
  font-size: 35px;
  font-weight: bold;
  padding-bottom: 30px;
}
.freeHostingHeadingMobile {
  padding-top: 30px;
  font-family: 'Montserrat', sans-serif;
  font-size: 29px;
  font-weight: bold;
  padding-bottom: 30px;
  color: #003d66;
}
.freeHostingDescription {
  font-family: 'Montserrat', sans-serif;
  font-size: 20px;
  font-weight: lighter;
  border-bottom: 1px solid black;
  padding-bottom: 20px;
}
.jobsPage {
  padding-top: 120px;
  margin-left: 20%;
  margin-right: 20%;
  padding-bottom: 30px;
}
.jobsDesc {
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
}
.currentJobs {
  padding-top: 120px;
  margin-left: 20%;
  margin-right: 20%;
  padding-bottom: 30px;
}
.currentJobsHeading {
  font-family: 'Montserrat', sans-serif;
  font-size: 28px;
  padding-bottom: 30px;
  border-bottom: 1px solid black;
}
.currentJobsDescription {
  font-family: 'Montserrat', sans-serif;
  font-weight: lighter;
}
.aboutDesc {
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
}
.aboutHeadingCreate {
  font-family: 'Montserrat', sans-serif;
  font-weight: lighter;
  font-size: 28px;
  padding-bottom: 10px;
}
.btn-start-building {
  font-size: 12px;
  width: 160px;
  height: 40px;
  color: #083961;
  background: #fff;
  box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.2);
}
.bodyArea {
  background-color: #f5f6fa;
}
.pricingPlans {
  padding-top: 20px;
  padding-bottom: 20px;
}
.bumpDown {
  padding-top: 45px;
}
.eCommercePlan {
  text-align: center;
  border-radius: 7px;
  background-color: white;
  padding-bottom: 70px;
}
.startupPlan {
  color: black;
  text-align: center;
  border-radius: 7px;
  background-color: white;
  padding-bottom: 71px;
}
.basicPlan {
  text-align: center;
  border-radius: 7px;
  background-color: white;
  padding-bottom: 70px;
}
.featuredPlanTop {
  padding-top: 10px;
  padding-left: 1px;
  padding-right: 1px;
  color: white;
  text-align: center;
  border-radius: 7px;
  height: 766px;
  background-color: #0a4168;
  box-shadow: 9px 4px 321px 0 rgba(189, 189, 189, 0.5);
}
.featuredPlanTopMobile {
  padding-top: 10px;
  padding-left: 1px;
  padding-right: 1px;
  color: white;
  text-align: center;
  border-radius: 7px;
  height: 685px;
  background-color: #0a4168;
  box-shadow: 9px 4px 321px 0 rgba(189, 189, 189, 0.5);
}
.planHeading {
  font-family: Montserrat;
  padding-top: 30px;
  display: inline-block;
  font-size: 20px;
  width: 111px;
  font-weight: bold;
}
.planSubheading {
  font-family: Montserrat;
  font-size: 14px;
  color: #000000;
  display: inline-block;
  width: 100%;
  line-height: 40px;
}
.basicPlan {
  text-align: center;
  border-radius: 7px;
  background-color: white;
}
.headUp {
  padding-bottom: 10px;
}
.planPrice {
  font-family: 'Montserrat', sans-serif;
  text-align: center;
  font-size: 40px;
  font-weight: bold;
  display: inline-block;
  margin: 20px 0;
  background: rgba(0, 55, 93, 0.21);
  border-radius: 8px;
  width: 40%;
}
.planPriceMobile {
  font-family: 'Montserrat', sans-serif;
  text-align: center;
  font-size: 40px;
  font-weight: bold;
  display: inline-block;
  margin: 20px 0;
  background: rgba(0, 55, 93, 0.21);
  border-radius: 8px;
  width: 44%;
}
.planPrice sup {
  font-size: 20px;
}
.planPriceMobile sup {
  font-size: 20px;
}
.planPriceMonth {
  margin-left: 80px;
  margin-top: -20px;
  font-size: 13px;
}
.push {
  margin-left: 129px;
  margin-top: -30px;
  padding-bottom: 10px;
}
.planFeatures {
  font-family: 'Montserrat', sans-serif;
  font-size: 15px;
  line-height: 24px;
  display: inline-block;
  margin-left: 25%;
  width: 100%;
  text-align: left;
  padding-top: 20px;
}
.planFeaturesMobile {
  font-family: 'Montserrat', sans-serif;
  font-size: 15px;
  line-height: 24px;
  display: inline-block;
  text-align: left;
  padding-top: 20px;
}
.lineBar {
  border-top: 1px #ccc solid;
  width: 70%;
  margin-left: 15%;
  margin-right: 10%;
}
.clear {
  clear: both;
}

/* PricingAddon */
.pricingAddonBackground {
  margin-left: 8%;
  margin-right: 8%;
}
.pricingAddon {
  background: #fff;
  border-radius: 5px;
  padding: 25px;
}
.addonHeaderText {
  font-family: 'Montserrat', sans-serif;
  font-size: 23px;
  width: 45%;
  font-weight: bold;
  line-height: 57px;
  padding-top: 10px;
  padding-left: 20px;
}
.pricingWrapper {
  width: 100%;
  padding-right: 20px;
}
.totalPriceBackground {
  font-family: 'Montserrat', sans-serif;
  background: rgba(0, 55, 93, 0.21);
  margin-top: 5%;
  margin-left: 69%;
  border-radius: 8px;
  font-size: 50px;
  font-weight: 700;
  width: 33%;
}
.totalPriceBackground sup {
  font-size: 20px;
  top: -1em;
}
.totalPrice {
  margin-top: -13px;
  text-align: right;
  padding-right: 48px;
}
.totalPriceLabel {
  padding-top: 5px;
  margin-left: 10px;
  font-weight: lighter;
  font-size: 10px;
}
.addonOptions {
  display: inline-block;
  padding-top: 60px;
  padding-bottom: 10px;
}
.addonOptionSpacer {
  display: inline-block;
}
.optionItem {
  font-family: 'Montserrat', sans-serif;
  padding-left: 10px;
  padding-bottom: 20px;
}
.bucket {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.bucket input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.bucket:hover input ~ .checkmark {
  background: rgba(27, 99, 220, 0.9);
}
.bucket input:checked ~ .checkmark {
  background: rgba(27, 99, 220, 0.09);
}
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 17px;
  width: 17px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.08);
  border-radius: 3px;
  border: 1px solid #1b63dc;
  background: rgba(27, 99, 220, 0.09);
}
.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}
.bucket input:checked ~ .checkmark:after {
  display: block;
}
.bucket .checkmark:after {
  left: 6px;
  top: -11px;
  width: 12px;
  height: 25px;
  border: solid #1b63dc;
  border-width: 0 6px 6px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.optionLabel {
  width: 60%;
  padding-left: 35px;
}
.optionLabel span {
  font-weight: bold;
  float: right;
}
.alignColumn {
  padding-top: 61px;
}
.loginForm {
  margin-left: 30%;
  margin-right: 30%;
  margin-bottom: 10%;
  padding: 20px;
  box-shadow: 0 7px 14px 0 rgba(60, 66, 87, 0.12),
    0 3px 6px 0 rgba(0, 0, 0, 0.12);
}
.loginFormMobile {
  margin-left: 4%;
  margin-right: 4%;
  margin-bottom: 10%;
  padding: 20px;
  box-shadow: 0 7px 14px 0 rgba(60, 66, 87, 0.12),
    0 3px 6px 0 rgba(0, 0, 0, 0.12);
}
.loginBackground {
  background: #f4f6f9;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding-top: 110px;
}
.loginBackgroundMobile {
  background: #f4f6f9;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.signupForm {
  border-radius: 10px;
  padding: 40px;
  width: 540px;
  box-shadow: 0 2px 5px 0 rgb(0 0 0 / 10%);
}
.signupBackground {
  background: #f4f6f9;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.alignHeader {
  padding-top: 150px;
  width: 100%;
}
.alignHeaderMobile {
  width: 100%;
}
.signupPoints img {
  display: block;
  max-width: 361px;
  max-height: 244px;
  width: auto;
  height: auto;
}
.signupPointsMobile img {
  display: block;
  max-width: 361px;
  max-height: 244px;
  width: auto;
  height: auto;
  padding-bottom: 40px;
}
.signupHeader {
  font-family: 'Montserrat', sans-serif;
  word-wrap: break-word;
  width: 140px;
  color: #003d66;
  font-size: 28px;
  font-weight: bold;
  padding-bottom: 50px;
}
.signupCard {
  padding-top: 10px;
  height: 400px;
  width: 340px;
  box-shadow: 0 7px 14px 0 rgba(60, 66, 87, 0.12),
    0 3px 6px 0 rgba(0, 0, 0, 0.12);
}
.MuiFormLabel-root {
  color: #000 !important;
}
.signupCard input {
  color: #000 !important;
  position: static;
  height: 50px;
  width: 280px;
  border: 1px solid black;
  opacity: 1;
  padding-bottom: 10px;
}
.emailInput {
  margin-top: 10%;
}
.submitButton {
  margin-top: 8%;
  margin-left: 5%;
}
.metismenu {
  position: fixed;
  width: 280px;
  bottom: 0;
  left: 0;
  top: 0;
}
.metismenu-link.active {
  background: rgba(27, 99, 240, 0.5);
}
.metismenu-link.has-active-child {
  color: #1b63dc;
}
.adminDashboardHeading {
  padding-top: 20px;
  font-size: 25px;
}
.newSignupsBarChart {
  height: 400px;
}
.wizardHeader {
  width: 50%;
  margin: 0 auto;
}
.logoWiz {
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
  font-size: 32px;
  padding-top: 20px;
  padding-left: 30px;
  text-align: center;
  letter-spacing: -1.78px;
  color: #003d66;
}
.progressBar {
  text-align: center;
  padding: 40px;
}
.progressDot {
  width: 20px;
  height: 20px;
}
.progressLine {
  margin-left: 20px;
  margin-right: 20px;
  width: 91px;
  height: 6px;
}
.leadIntro {
  font-family: 'Montserrat', sans-serif;
  text-align: center;
  font-size: 22px;
  color: #003d66;
  padding-bottom: 20px;
}
.pickProduct {
  background: #f4f6f9;
  padding: 40px;
}
.selectWebsite {
  width: 376px;
  height: 306px;
  background: #ffffff;
  border-radius: 8px;
}
.selectBlog {
  width: 376px;
  height: 306px;
  background: #ffffff;
  border-radius: 8px;
}
.selecteCommerce {
  width: 376px;
  height: 306px;
  background: #ffffff;
  border-radius: 8px;
}
.dividerSpace {
  padding-top: 20px;
}
.selectJournal {
  width: 376px;
  height: 306px;
  background: #ffffff;
  border-radius: 8px;
}
.selectPersonal {
  width: 376px;
  height: 306px;
  background: #ffffff;
  border-radius: 8px;
}
.selectEmail {
  width: 376px;
  height: 306px;
  background: #ffffff;
  border-radius: 8px;
}
.productHeader {
  padding: 20px;
  font-family: 'Montserrat', sans-serif;
  font-size: 25px;
  font-weight: bold;
  color: #003d66;
}
.svgIcon {
  text-align: center;
}
.raise:hover,
.raise:focus {
  box-shadow: 0 0.5em 0.5em -0.4em;
  transform: translateY(-0.25em);
}
.spacerFromLogo {
  padding-bottom: 30px;
}
.loginErrorMessage {
  text-align: center;
  padding: 10px;
  background: #fff;
  color: red;
}
.MuiButtonBase-root {
  margin-right: 20px !important;
}
.centerLogo {
  text-align: center;
}
.mainPanel {
  padding-top: 50px;
  margin: 15px;
  border-radius: 7px;
  border: 1px solid grey;
  padding: 10px;
}
.saveBtn {
  margin-right: 20px;
}
.selectPlan {
  font-family: 'Montserrat', sans-serif;
  font-size: 18px;
  color: #003d66;
}
.basicPlanPopup {
  padding-top: 10px;
  text-align: center;
  font-size: 17px;
  font-weight: bold;
  color: #003d66;
}
.startupPlanPopup {
  padding-top: 10px;
  text-align: center;
  font-size: 17px;
  font-weight: bold;
  color: #003d66;
}
.ecommercePlanPopup {
  padding-top: 10px;
  text-align: center;
  font-size: 17px;
  font-weight: bold;
  color: #003d66;
}
.planPricePopup {
  text-align: center;
  font-family: 'Montserrat', sans-serif;
  font-size: 20px;
  font-weight: bold;
  vertical-align: text-bottom;
  color: #003d66;
}
.planPricePopupBg {
  background: #003d66;
  border-radius: 5px;
  width: 40%;
}
.planPopupSub {
  font-size: 11px;
  text-align: center;
  cursor: pointer;
  color: #003d66;
}
.forgotPassBanner {
  min-height: 100vh;
  background-color: #f6f6f6;
}
.forgotPassContainer {
  margin: 0 auto;
  position: relative;
  padding: 25px 30px;
  width: 540px;
  border-radius: 10px;
  box-shadow: 0 2px 5px 0 rgb(0 0 0 / 10%);
  background-color: #fff;
}
.forgotPass {
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
  font-size: 24px;
}
.forgotPassFooter {
  align-items: center;
  display: flex;
  justify-content: flex-end;
}
.forgotPassFooter a {
  padding-right: 5%;
}
.forgotPassBtn {
  -webkit-font-smoothing: antialiased;
  font-size: 18px;
  line-height: 1;
  appearance: button;
  padding: 14px 24px;
  background: linear-gradient(180deg, #2fa42f, #408c40);
  color: #fff;
  font-weight: bold;
  text-decoration: none;
  text-align: center;
  vertical-align: middle;
  outline: 0;
  border-radius: 4px;
  border: 1px solid #ccc;
  display: inline-block;
}
.forgotPassBtn:hover {
  color: #fff;
}
.termsOfUse {
  padding-left: 20%;
  padding-right: 20%;
}
.personalNote {
  color: gray;
}
.privacy {
  padding-left: 20%;
  padding-right: 20%;
}
.dashboardLayout {
  padding-top: 5%;
  padding-left: 10%;
  padding-right: 10%;
}
.sitePanel {
  width: 425px;
  height: 270px;
  position: relative;
  background: #f8f8f8;
  border-radius: 8px;
  border: 1px solid #ffffff;
  font-family: Montserrat;
  font-size: 15px;
  color: #003d66;
  letter-spacing: -0.18px;
  margin-bottom: 80px;
}
.gemstateHearing {
  background: url(images/templates/gemState.png) no-repeat center;
  background-position-y: 100%;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  width: 425px;
  height: 270px;
  position: relative;
  border-radius: 8px;
  border: 1px solid #ffffff;
  font-family: Montserrat;
  font-size: 15px;
  color: #003d66;
  letter-spacing: -0.18px;
  margin-bottom: 80px;
}
.sitePanelPhotography {
  background: url(images/templates/photography.png) no-repeat center;
  background-position-y: 10%;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  width: 425px;
  height: 270px;
  position: relative;
  border-radius: 8px;
  border: 1px solid #ffffff;
  font-family: Montserrat;
  font-size: 15px;
  color: #003d66;
  letter-spacing: -0.18px;
  margin-bottom: 80px;
}
.sitePanelPersonal {
  background: url(images/templates/personal.png) no-repeat center;
  background-position-y: 100%;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  width: 425px;
  height: 270px;
  position: relative;
  border-radius: 8px;
  border: 1px solid #ffffff;
  font-family: Montserrat;
  font-size: 15px;
  color: #003d66;
  letter-spacing: -0.18px;
  margin-bottom: 80px;
}
.sitePanelYoga {
  background: url(images/templates/yoga.png) no-repeat center;
  background-position-y: 100%;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  width: 425px;
  height: 270px;
  position: relative;
  border-radius: 8px;
  border: 1px solid #ffffff;
  font-family: Montserrat;
  font-size: 15px;
  color: #003d66;
  letter-spacing: -0.18px;
  margin-bottom: 80px;
}
.sitePanelWedding {
  background: url(images/templates/weddings.png) no-repeat center;
  background-position-y: 100%;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  width: 425px;
  height: 270px;
  position: relative;
  border-radius: 8px;
  border: 1px solid #ffffff;
  font-family: Montserrat;
  font-size: 15px;
  color: #003d66;
  letter-spacing: -0.18px;
  margin-bottom: 80px;
}
/* Template selection */
.templateContainer {
  position: relative;
  width: 100%;
}
.templateOverlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: 0.5s ease;
  background-color: #fff;
}
.templateContainer:hover .templateOverlay {
  opacity: 0.9;
}
.templateText {
  font-family: Montserrat;
  color: black;
  font-size: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}

/* Global Footer */
.fMYymi {
  padding-top: 20px;
  width: 100%;
  color: rgb(0, 0, 0);
  font-size: 16px;
  line-height: 16px;
}
.hXBwhW {
  height: 171px;
}
.hXBwhW {
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  flex-direction: column;
  height: 126px;
  display: flex;
}
.kCjoBV {
  margin-bottom: 40px;
  justify-content: space-around;
  display: flex;
}
.mupZQ {
  margin-right: 20px;
  color: rgb(0, 0, 0);
  font-size: 14px;
  font-family: Arquitecta, Helvetica, Arial, sans-serif;
  letter-spacing: 0.08em;
  line-height: 105%;
  text-transform: uppercase;
  font-weight: 500;
}
a {
  cursor: pointer;
  text-decoration: none;
  transition: color 0.25s ease-in-out 0s;
  color: #0070d9;
}
.bqRcbO {
  -webkit-box-pack: center;
  justify-content: center;
  display: flex;
}
.fwvDOH {
  background-color: rgb(225, 225, 225);
  height: 1px;
}
.menu-divider {
  width: 1px;
  height: 24px;
  display: inline-block;
  cursor: default;
  line-height: 42px;
  user-select: none;
  margin: 9px 2px;
  background: rgba(100, 100, 100, 0.2);
  overflow: hidden;
}
.editorButton:hover {
  color: #d5d5d5;
}
.editorButton:active {
  color: #eb6329;
}
.editorButton:focus {
  color: #eb6329;
}
.recharts-pie-labels {
  font-family: 'Montserrat', sans-serif;
  font-size: 26px;
  font-weight: bold;
  width: 30% !important;
}

/* Domain Registration */
.domainRegSection {
  height: 900px;
  background-color: #003d66;
}
.domainRegSectionMobile {
  height: 700px;
  background-color: #003d66;
}
.domainHeading {
  font-family: 'Montserrat', sans-serif;
  font-size: 52px;
  font-weight: bold;
  text-align: center;
  color: white;
  padding-top: 180px;
}
.domainHeadingMobile {
  font-family: 'Montserrat', sans-serif;
  font-size: 32px;
  font-weight: bold;
  text-align: center;
  color: white;
  padding-top: 90px;
  padding-bottom: 20px;
}
.domainInputArea {
  padding-top: 40px;
  text-align: center;
}
.domainInputArea input {
  font-family: Montserrat-Regular;
  font-size: 22px;
  margin: auto;
  height: 52px;
  border: none;
  font-size: 22px;
}
.domainSelect {
  font-family: Montserrat-Regular !important;
  font-size: 20px !important;
  width: 100% !important;
  color: rgb(0, 61, 102) !important;
}
.domainInputAreaMobile input {
  font-family: Montserrat-Regular;
  font-size: 22px;
  margin: auto;
  height: 52px;
  border: none;
  font-size: 22px;
}
.domainInput::placeholder {
  font-family: Montserrat-Regular;
  color: #c3c3c3;
}
.domainButton {
  background: #dd5d27;
  box-shadow: 0 1px 4px 0 rgba(40, 40, 40, 0.5);
  border-radius: 8px;
  color: white;
  border: none;
  width: 140px;
  height: 52px;
}
.domainButtonMobile {
  background: #dd5d27;
  box-shadow: 0 1px 4px 0 rgba(40, 40, 40, 0.5);
  border-radius: 8px;
  color: white;
  border: none;
  width: 88px;
  height: 52px;
}

.mobileDomainInput {
  margin: auto;
  width: 82% !important;
}
.domainAdvertArea {
  padding-top: 37px;
  text-align: center;
  margin: auto;
  width: 55% !important;
}
.domainAdvertAreaMobile {
  padding-top: 37px;
  text-align: center;
  margin: auto;
  width: 81% !important;
}
.domainAdvert {
  height: 244px;
  background: #08578c;
  border-radius: 8px;
}
.domainAdvertMobile {
  height: 293px;
  background: #08578c;
  border-radius: 8px;
}
.domainTypesList {
  padding-top: 20px;
  padding-right: 74px;
  font-family: Montserrat-Bold;
  font-size: 22px;
  color: white;
  display: inline-block;
}
.domainTypesListMobile {
  padding-top: 20px;
  padding-right: 19px;
  font-family: Montserrat-Bold;
  font-size: 22px;
  color: white;
  display: inline-block;
}
.redDot {
  padding-left: 70px;
  font-family: Montserrat-Bold;
  font-size: 22px;
  color: #dd5d27;
  display: inline-block;
}
.redDotMobile {
  padding-left: 16px;
  font-family: Montserrat-Bold;
  font-size: 22px;
  color: #dd5d27;
  display: inline-block;
}
.circleSVG {
  padding-top: 35px;
}
.oneWithCircle {
  position: relative;
  top: -58px;
  font-family: LucidaGrande-Bold;
  font-size: 41px;
  color: #ffffff;
  text-align: center;
}
.salesSloganFreeDomain {
  margin-top: -57px;
  font-family: Montserrat-Bold;
  font-size: 22px;
  color: #ffffff;
  text-align: center;
}
/* Email Client */
.loaderTextEmails {
  font-family: Montserrat-Bold;
  font-size: 30px;
  display: block;
}
.pageSettings {
  height: 100% !important;
}
.firstColumnCornerBox {
  font-family: Montserrat-Bold;
  font-size: 30px;
  color: #808080 !important;
  display: flex;
  align-items: center;
  padding: 32px;
}
.secondColumnSearchArea {
  height: 116px;
  padding: 30px;
  background-color: #003d66 !important;
  display: flex;
  align-items: center;
}
.composeNewEmail {
  font-family: 'Montserrat';
  padding: 10px;
}
.alignEmailTextTo {
  display: inline-block;
  padding-top: 18px;
}
.emailMessageBody {
  min-height: 500px;
}
.public-DraftEditor-content {
  min-height: 500px;
}
.emailCount {
  background: #dd5e28;
  color: #ffffff !important;
  font-size: 13px;
  border-radius: 50%;
  padding-top: 2px;
  padding-left: 8px;
  padding-right: 8px;
  margin-left: 25px;
  padding-bottom: 2px;
  display: inline-block;
}
.inBoxHighLight {
  width: 8px;
  height: 70px;
  background: #547a95 !important;
}
.paddingForHighLight {
  width: 8px;
  height: 70px;
}
.inBoxSection.activeMailTab {
  color: #ffffff !important;
  background: #181922 !important;
}
.inBoxSection {
  font-family: Montserrat-Bold;
  font-size: 20px;
  height: 70px;
  background: #242635 !important;
  color: #808080 !important;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.sentEmailSection.activeMailTab {
  color: #ffffff !important;
  background: #181922 !important;
}
.sentEmailSection {
  font-family: Montserrat-Bold;
  font-size: 20px;
  height: 70px;
  background: #242635 !important;
  color: #808080 !important;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.secondColumnSearchArea input {
  height: 7px;
  width: 448px;
  color: #ffffff !important;
}
.secondColumnEmailContent {
  font-family: Montserrat-Regular;
  padding-left: 20px;
  color: #808080 !important;
}
.thirdColumnEmailContent {
  font-family: Montserrat-Regular;
  padding-left: 12px;
  padding-right: 20px;
  color: #808080 !important;
  border-left: 2px solid #d8d8d8;
  margin-left: 20px;
  height: 100%;
}
.activeEmail {
  font-weight: 600;
  background: #ecf5fc;
}
.unreadEmail {
  font-weight: 600;
}
.emailNamePreview {
  padding-left: 10px;
  padding-bottom: 5px;
}
.emailSubjectPreview {
  padding-left: 10px;
}
.emailName {
  font-weight: 500;
  padding: 20px;
  font-size: 21px;
}
.emailAddress {
  padding-left: 10px;
}
.emailSubject {
  padding-left: 10px;
  padding-bottom: 10px;
}
.emailMessage {
  border-bottom: 2px solid #d8d8d8;
}
.emailReplyTextArea {
  padding-top: 50px;
  padding-left: 20px;
  padding-right: 2px;
}
.emailReply {
  resize: none;
  border-radius: 8px;
  border: 1px solid #979797;
  width: 100%;
  padding: 10px;
}
.sendBtnArea {
  text-align: right;
}
.sendBtn {
  font-family: Montserrat-Regular;
  color: #ffffff !important;
  background-color: #547a95 !important;
  font-size: 15px !important;
  padding: 4px 23px !important;
  margin-right: 0px !important;
}
.emailListHeader {
  padding-top: 30px;
  padding-bottom: 10px;
  border-bottom: 2px solid #898989;
}
.emailItem {
  padding-top: 20px;
  padding-bottom: 20px;
  border-bottom: 2px solid #898989;
  cursor: pointer;
}
.emailAddress {
  padding-top: 10px;
}
.emailDate {
  text-align: right;
  padding-right: 10px;
}
.composeBtn {
  font-family: Montserrat-Regular;
  color: #ffffff !important;
  background-color: #547a95 !important;
  font-size: 15px !important;
  padding: 4px 23px !important;
}
.fillerSection {
  background: #242635 !important;
  height: 70%;
}
.bottomColumns {
  height: 100%;
}

/* Boost Traffic */
.introTextDate {
  font-family: Montserrat-Bold;
  font-size: 30px;
  color: #808080;
}
.backgroundAccent {
  background: #fafafb !important;
  padding: 13px;
}
.centerFocus {
  font-family: Montserrat-Regular;
  padding: 5px;
}
.webPageStatsTitle {
  font-family: Montserrat-Regular;
  text-align: left;
  font-size: 18px;
  padding-top: 20px;
}
.summaryTitle {
  font-family: Montserrat-Regular;
  font-size: 18px;
  padding-top: 24px;
}
.MuiLinearProgress-root {
  height: 23px !important;
}
.MuiLinearProgress-barColorPrimary {
  background-color: #b8e986 !important;
}
.MuiLinearProgress-colorPrimary {
  border: 1px solid #979797 !important;
  background-color: #e6e6e6 !important;
}
.eightyPercentBar > .MuiLinearProgress-barColorPrimary {
  background-color: #e1ff8e !important;
}
.seventyPercentBar > .MuiLinearProgress-barColorPrimary {
  background-color: #f7ff8e !important;
}
.sixtyPercentBar > .MuiLinearProgress-barColorPrimary {
  background-color: #fff28e !important;
}
.fiftyPercentBar > .MuiLinearProgress-barColorPrimary {
  background-color: #ffe38e !important;
}
.fourtyPercentBar > .MuiLinearProgress-barColorPrimary {
  background-color: #ffce8e !important;
}
.thirtyPercentBar > .MuiLinearProgress-barColorPrimary {
  background-color: #ffd0b4 !important;
}
.twentyPercentBar > .MuiLinearProgress-barColorPrimary {
  background-color: #ffbbac !important;
}
.alignData {
  padding-top: 43px;
}
.fakeDivider {
  border-left: 1px solid #e6e6e6;
}
.pageStatSection {
  padding-top: 20px;
  width: 94%;
  color: #808080;
}
.boostPageBuffer {
  padding-top: 54px;
}
.buttonSpacing {
  padding-bottom: 7px;
}
/* Internal Domain Registration */
.centerRegisterDomain {
  height: 700px;
}
.positionDomainSearch {
  font-family: Montserrat-Bold;
  font-size: 42px;
  padding-top: 80px;
}

/* Interal Editor */
.MuiFab-primary {
  background-color: rgb(0, 61, 102) !important;
}

.widgetIcons {
  font-size: 50px !important;
  color: #003d66;
}
.widgetIcons:hover {
  color: #d05a28;
  cursor: pointer;
}

/* Internal Analytics */
.graphTitle {
  font-family: Montserrat-Bold;
  font-size: 22px;
  color: #565656 !important;
  padding-top: 30px;
}

/* Internal Storage  */
.amountStorageUsed {
  font-family: Montserrat-Bold;
  font-size: 22px;
  padding: 20px;
  color: #565656 !important;
  width: 98%;
  height: 281px;
}
.totalStorageAmount {
  font-family: Montserrat-Bold;
  font-size: 22px;
  text-align: center;
}
.storageUsageStatsText {
  color: #b8b8b8;
}
.dataUsage {
  font-family: Montserrat-Bold;
  font-size: 22px;
  padding: 20px;
  color: #565656 !important;
  width: 100%;
  height: 632px;
}
.notificationContent {
  margin-top: 10px;
  border-left: 8px solid #d0021b;
}
.dataNotifications {
  padding: 10px;
  font-family: Montserrat-Regular;
  font-size: 17px;
  border-top: 3px solid #e6e6e6;
  border-bottom: 3px solid #e6e6e6;
  color: #a9a4a5;
}
.numberNotifications {
  font-family: Montserrat-Bold;
  color: #003d66;
}
.dataTemplateUsage {
  font-family: Montserrat-Regular;
  font-size: 17px;
  border-bottom: 3px solid #e6e6e6;
  padding: 10px;
}
.dataTemplateTitle {
  float: left;
  padding-bottom: 10px;
}
.dataTemplateAmountUsed {
  float: right;
}
.dataTemplateDetails {
  clear: both;
  padding-left: 10px;
  font-size: 15px;
  color: #a9a4a5;
}
.dataImagesUsage {
  font-family: Montserrat-Regular;
  font-size: 17px;
  border-bottom: 3px solid #e6e6e6;
  padding: 10px;
}
.dataImagesTitle {
  float: left;
  padding-bottom: 10px;
}
.dataImagesAmountUsed {
  float: right;
}
.dataImageDetails {
  clear: both;
  padding-left: 10px;
  font-size: 15px;
  color: #a9a4a5;
}
.dataVideosUsage {
  font-family: Montserrat-Regular;
  font-size: 17px;
  border-bottom: 3px solid #e6e6e6;
  padding: 10px;
}
.dataVideosTitle {
  float: left;
  padding-bottom: 10px;
}
.dataVideosAmountUsed {
  float: right;
}
.dataVideosDetails {
  clear: both;
  padding-left: 10px;
  font-size: 15px;
  color: #a9a4a5;
}
.dataFormDataUsage {
  font-family: Montserrat-Regular;
  font-size: 17px;
  border-bottom: 3px solid #e6e6e6;
  padding: 10px;
}
.dataFormDataTitle {
  float: left;
  padding-bottom: 10px;
}
.dataFormDataAmountUsed {
  float: right;
}
.dataFormDataDetails {
  clear: both;
  padding-left: 10px;
  font-size: 15px;
  color: #a9a4a5;
}
.upgradeArea {
  font-family: Montserrat-Bold;
  font-size: 22px;
  padding: 20px;
  color: #565656 !important;
  width: 98%;
  height: 327px;
}
.currentPlan {
  font-family: Montserrat-Bold;
  font-size: 22px;
  padding: 20px;
  height: 246px;
  color: #565656 !important;
  background-color: #f8f8f8 !important;
  text-align: center;
}
.storageDataPlanWrapper {
  padding-top: 50px;
  padding-left: 30px;
  padding-right: 30px;
}
.upgradePrice {
  font-family: Montserrat-Regular;
  padding: 20px;
}
.upgradeBtn {
  background-color: #003d66 !important;
  margin-right: 0px !important;
}
.storageDataPlan {
  text-align: center;
  border-radius: 8px;
  border: 1px solid #fff;
  width: 118px;
}
.thirtyGigPlan {
  font-family: Montserrat-Bold;
  font-size: 22px;
  padding: 20px;
  height: 246px;
  color: #565656 !important;
  background-color: #f8f8f8 !important;
  border-left: 2px solid #f0f0f0;
  text-align: center;
}
.fourtyGigPlan {
  font-family: Montserrat-Bold;
  font-size: 22px;
  padding: 20px;
  height: 246px;
  color: #565656 !important;
  background-color: #f8f8f8 !important;
  border-left: 2px solid #f0f0f0;
  text-align: center;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}
.upgradeOptions {
  padding-top: 10px;
}
.tenPercentBar > .MuiLinearProgress-barColorPrimary {
  background-color: #efb328 !important;
}
.storageBarHeight.MuiLinearProgress-root {
  height: 72px !important;
}
.tenPercentBar.MuiLinearProgress-colorPrimary {
  border: 0px !important;
  background-color: #abcc00 !important;
}

/* Online Store Dashboard */
.quickStartArea {
  font-family: Montserrat-Bold;
  font-size: 22px;
  color: #565656 !important;
  width: 98%;
  height: 261px;
  padding: 20px;
}
.quickStartActions {
  color: #565656 !important;
  height: 145px;
  border-right: 1px solid #979797;
}
.quickStartButtons {
  padding: 10px;
}
.topItemsArea {
  color: #565656 !important;
  padding-left: 25px;
}
.bottomMenuArea {
  font-family: Montserrat-Regular;
  font-size: 18px;
  padding-top: 50px;
}
.numberHighlight {
  font-family: Montserrat-Bold;
  text-align: center;
  width: 25px;
  color: white;
  border-radius: 4px;
  background-color: #003d66;
  display: inline-block;
  margin-right: 10px;
}
.ordersSection {
  width: 84%;
  border-right: 1px solid #979797;
}
.productsSection {
  width: 91%;
  border-right: 1px solid #979797;
}
.categoriesSection {
  width: 100%;
  border-right: 1px solid #979797;
}
.customersSection {
  width: 95%;
  margin-left: 15px;
}
.notificationsArea {
  font-family: Montserrat-Bold;
  font-size: 22px;
  color: #565656 !important;
  height: 261px;
  border: 3px solid #979797;
  padding: 20px;
}
.notificationItem {
  font-family: Montserrat-Regular;
  padding: 20px;
  color: #000000 !important;
  font-size: 14px;
  border-bottom: 1px solid #979797;
}
.recentOrders {
  font-family: Montserrat-Bold;
  font-size: 22px;
  color: #565656 !important;
  width: 98%;
  height: 552px;
  padding: 20px;
}
.orderTypes {
  font-family: Montserrat-Regular;
  margin-top: 20px;
  padding: 12px;
  height: 46px;
  background-color: #e6e6e6;
  font-size: 14px;
  cursor: pointer;
}
.ordersSortActive {
  background-color: white;
  border-radius: 4px;
  text-align: center;
  width: 115px;
  display: block;
  font-weight: bold;
}
.refundedOrders {
  padding-left: 10px;
}
.orderData {
  font-family: Montserrat-Regular;
  font-size: 14px;
}
.orderType {
  font-family: 'Montserrat';
  font-weight: 600;
  font-size: 14px;
  border-radius: 4px;
  color: #fff;
  text-align: center;
}
.allOrdersBtn {
  margin-right: -3px !important;
}
.orderDetailHeader {
  font-family: 'Montserrat';
  font-weight: 600;
  font-size: 14px;
}
.orderDetailDesc {
  font-size: 13px;
  color: #808080;
}
.storeSales {
  font-family: Montserrat-Bold;
  font-size: 22px;
  color: #565656 !important;
  height: 264px;
  padding: 20px;
}
.storeSalesGraph {
  font-family: Montserrat-Bold;
  font-size: 22px;
  color: #565656 !important;
  height: 264px;
  padding: 20px;
}
.salesGraph {
  padding-top: 10px;
  font-family: Montserrat-Regular;
  font-size: 14px !important;
  height: 90% !important;
  margin-left: -10px !important;
}
.storeFilterBarLeft {
  font-family: 'Montserrat';
  font-weight: 500;
  background-color: #003d66;
  color: #dedede;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  font-size: 14px;
  padding-left: 10px;
  padding-top: 7px;
  max-width: 15% !important;
  text-align: center;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.storeFilterBarMiddle {
  font-family: 'Montserrat';
  font-weight: 500;
  background-color: #003d66;
  color: #fff;
  font-size: 14px;
  padding-left: 10px;
  padding-top: 7px;
  max-width: 15% !important;
  border-right: 1px solid #fff;
  text-align: center;
  cursor: pointer;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.storeFilterBarRight {
  font-family: 'Montserrat';
  font-weight: 500;
  background-color: #003d66;
  color: #fff;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  font-size: 14px;
  padding-left: 2px;
  padding-top: 7px;
  max-width: 15% !important;
  text-align: center;
  cursor: pointer;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.revenueGridTop {
  padding-top: 30px;
}
.revenueGridTitle {
  font-family: Montserrat-Regular;
  font-size: 16px;
  text-align: right;
  padding-top: 14px;
  padding-right: 20px;
}
.revenueGridFirst {
  font-family: 'Montserrat';
  font-weight: bold;
  font-size: 16px;
  padding: 13px;
  text-align: center;
  border-bottom: 1px solid black;
  border-right: 1px solid black;
}
.revenueGridLast {
  font-family: 'Montserrat';
  font-weight: bold;
  font-size: 16px;
  padding: 13px;
  text-align: center;
  border-bottom: 1px solid black;
}
.revenueGridMiddleFirst {
  font-family: Montserrat-Regular;
  font-size: 16px;
  padding: 13px;
  text-align: center;
  border-bottom: 1px solid black;
  border-right: 1px solid black;
}
.revenueGridMiddleLast {
  font-family: Montserrat-Regular;
  font-size: 16px;
  padding: 13px;
  text-align: center;
  border-bottom: 1px solid black;
}
.revenueGridBottomFirst {
  border-right: 1px solid black;
}
.revenueGridBottomLast {
  padding: 10px;
}

/* notification counter */
.notification-container {
  position: relative;
  width: 16px;
  height: 16px;
  top: 15px;
  left: 15px;

  /* i {
    color: #fff;
  } */
}

.notification-counter {
  position: relative;
  top: -27px;
  left: 52px;

  background-color: rgba(212, 19, 13, 1);
  color: #fff;
  border-radius: 3px;
  padding: 1px 3px;
  font: 8px Verdana;
  display: inline;
}

.shoppingCart-counter {
  position: relative;
  top: -13px;
  left: 54px;

  color: rgb(221, 94, 40);
  border-radius: 3px;
  padding: 1px 6px;
  font: 16px Verdana;
  display: inline;
}

.templateTitle {
  font-family: Montserrat-Bold;
  font-size: 22px;
  flex: 0 0 auto;
  margin: 0;
  padding: 16px 24px;
}

.templateCategory {
  font-family: Montserrat-Bold;
  color: rgb(0, 61, 102);
}

/* these make sure it can work in any text element */
.inline-text_copy--active,
.inline-text_input--active {
  font: inherit;
  color: inherit;
  text-align: inherit;
  padding: 0;
  background: none;
  border: none;
  outline: none;
}

.inline-text_copy--active {
  /* cursor: pointer; */
}

.inline-text_copy--hidden,
.inline-text_input--hidden {
  display: none;
}

.inline-text_input--active {
  text-align: left;
}

/* Contact Us Page */
.contactFormTitle {
  font-family: Montserrat-Regular;
  font-size: 18px;
}
.contactArea {
  padding-top: 20px;
  margin-left: 20%;
  margin-right: 20%;
  padding-bottom: 30px;
}
.contactAreaMobile {
  padding-top: 20px;
  margin-left: 5%;
  margin-right: 5%;
  padding-bottom: 30px;
}
.contactInputEmail input {
  width: 364px;
}
.contactInputEmailMobile input {
  width: 264px;
}
.contactInputMessage textarea {
  width: 364px;
}
.contactInputMessageMobile textarea {
  width: 264px;
}

/* Web Builder */
.webBuilderBody {
  padding-top: 90px;
}
.intuitiveFeatures {
  text-align: left;
  font-family: Montserrat-Bold;
  font-size: 31px;
  color: #003d66;
  letter-spacing: -1.78px;
  line-height: 70px;
  width: 79%;
  border-bottom: 1px solid #003d66;
}
.intuitiveFeaturesDesc {
  font-family: Montserrat-Regular;
  font-size: 18px;
  color: #003d66;
  padding-top: 20px;
}
.spacerInset {
  padding-top: 120px;
}
.webBuilderBizTemplates {
  padding-top: 20px;
  background: linear-gradient(
    491deg,
    rgba(255, 255, 255, 0.8),
    rgba(11, 0, 148, 100) 186.71%
  );
  /*background: linear-gradient(#EEEEEE -9deg, #003D66 calc(64% - 1px), transparent 64%);*/
}
.businessTemplates {
  text-align: left;
  font-family: Montserrat-Bold;
  font-size: 31px;
  color: #003d66;
  letter-spacing: -1.78px;
  line-height: 70px;
  width: 79%;
  border-bottom: 1px solid #003d66;
}
.businessTemplatesDesc {
  font-family: Montserrat-Regular;
  font-size: 18px;
  color: #003d66;
  padding-top: 20px;
}
.chefImage {
  margin-left: 7%;
}
/* Online Store */
.onlineStoreArea {
  padding-top: 20px;
}
.onlineStoreSelling {
  margin-right: 7%;
}
/* Email Hosting */
.emailFeatures {
  padding-top: 40px;
  padding-bottom: 40px;
  background: linear-gradient(
    491deg,
    rgba(255, 255, 255, 0.8),
    rgba(221, 92, 40, 100) 186.71%
  );
  /*background: linear-gradient(#EEEEEE -9deg, #003D66 calc(64% - 1px), transparent 64%);*/
}
/* Web Hosting */
.secureServ {
  text-align: left;
  font-family: Montserrat-Bold;
  font-size: 31px;
  color: #003d66;
  letter-spacing: -1.78px;
  line-height: 70px;
  width: 79%;
  margin-left: 15%;
  border-bottom: 1px solid #003d66;
}
.secureServDesc {
  font-family: Montserrat-Regular;
  font-size: 18px;
  color: #003d66;
  padding-top: 20px;
  margin-left: 15%;
}
.onDemand {
  text-align: left;
  font-family: Montserrat-Bold;
  font-size: 31px;
  color: #003d66;
  letter-spacing: -1.78px;
  line-height: 70px;
  width: 79%;
  margin-right: 15%;
  border-bottom: 1px solid #003d66;
}
.onDemandDesc {
  font-family: Montserrat-Regular;
  font-size: 18px;
  color: #003d66;
  padding-top: 20px;
  margin-right: 15%;
}

.MuiCircularProgress-colorPrimary {
  color: rgb(221, 94, 40) !important;
}
.editPageShoppingCartBg {
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #171725;
  opacity: 0.4;
  z-index: 1;
}
.editPageShoppingCart {
  background: #ffffff;
  position: fixed;
  padding: 32px 24px;
  height: 443px;
  max-width: 460px;
  width: 100%;
  top: 90px;
  right: 20px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  flex-direction: column;
  color: #171725;
  z-index: 10;
}
.shoppingCart-close {
  position: absolute;
  cursor: pointer;
  right: 26px;
  top: 26px;
}
.shoppingCart-title {
  font-size: 28px;
  font-weight: 700;
  align-self: flex-start;
}
.shoppingCart-table-scroll {
  max-height: 228px;
  overflow-y: auto;
  height: 100%;
}
.shoppingCart-table {
  width: 100%;
  margin-top: 12px;
  table-layout: fixed;
}
.shoppingCart-tableHead {
  font-size: 11px;
  color: #92929d;
  text-transform: uppercase;
  border-bottom: 1px solid #f1f1f5;
}
.shoppingCart-tableHead::after {
  line-height: 13px;
  content: '.';
  color: white;
  display: block;
}
.shoppingCart-tableContent {
  color: #171725;
  font-size: 14px;
  font-weight: 600;
  border-bottom: 1px solid #f1f1f5;
}
.shoppingCart-tableContent tr {
  height: 52px;
}
.shoppingCart-tableContent:before,
.shoppingCart-tableContent::after {
  line-height: 12px;
  content: '.';
  color: white;
  display: block;
}
.shoppingCart-tableContent tr td {
  padding-top: 14px;
  padding-bottom: 14px;
}
.shoppingCart-price {
  display: flex;
  gap: 12px;
}
.shoppingCart-price span {
  min-width: 10px;
}
.shoppingCart-removeItem {
  position: absolute;
  font-size: 18px;
  right: 0px;
  color: #a9a6a6;
  cursor: pointer;
}
.shoppingCart-total {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 24px;
  color: #171725;
  font-size: 20px;
  font-weight: 600;
  font-family: 'Inter', sans-serif;
  margin-top: auto;
}
.shoppingCartList {
  background: #dd5e28;
  color: #fff;
  padding: 20px;
  position: fixed;
  bottom: 102px;
}
div[class^='SnackbarItem-variantWarning'],
div[class*=' SnackbarItem-variantWarning'] {
  font-family: 'Montserrat';
  height: 75px;
  font-size: 20px;
  margin-bottom: 85px;
  background-color: rgb(221, 94, 40) !important;
}

div[class^='SnackbarItem-action'],
div[class*=' SnackbarItem-action'] {
  margin-left: 0;
  padding-left: 0;
}

/* Domain Manager */
.purchasedDomains {
  padding: 20px;
}
.domainTable .MuiTableCell-root {
  font-family: Montserrat-Regular !important;
  font-weight: 500;
  font-size: 20px;
}
.noSelect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
select {
  -webkit-tap-highlight-color: transparent; /* remove tap highlight */
}
select:focus {
  outline: none !important; /* remove outline */
  box-shadow: none !important; /* remove box shadow */
}
/* The ribbons */
.parent {
  overflow: hidden;
  position: relative;
}

.ribbon {
  font-family: Montserrat-Regular !important;
  font-weight: bold;
  font-size: 13px;
  margin: 0;
  padding: 0;
  background: #dd5c28;
  color: white;
  padding: 2px;
  position: absolute;
  top: 0;
  right: 0;
  transform: translateX(35%) translateY(-10%) rotate(45deg);
  transform-origin: top left;
}
.ribbon:before,
.ribbon:after {
  content: '';
  position: absolute;
  top: 0;
  margin: 0 -1px; /* tweak */
  width: 100%;
  height: 100%;
  background: #dd5c28;
}
.ribbon:before {
  right: 100%;
}

.ribbon:after {
  left: 100%;
}

/* Editor save/load progress bar */
.auto-save-display {
  position: absolute;
  top: 372px;
  right: 22px;
  z-index: 1;
  font-family: Montserrat-Regular !important;
  font-size: 12px;
  color: white;
  width: 63px;
  text-align: right;
  padding: 5px;
  background-color: #d68808;
}
.editorProgressBarContainer {
  width: 100% !important;
}
.editorProgressBar {
  border: 0 !important;
  height: 2px !important;
  color: red !important;
  background-color: white !important;
}
.editorProgressBar > .MuiLinearProgress-barColorPrimary {
  background-color: #dd5c28 !important;
}

.borderGradient {
  border: 4px solid #f5a623;
  border-radius: 9px;
}
.fadeIn {
  animation: fadeIn 2s;
  opacity: 1;
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

:focus {
  outline: none !important; /* no outline - for most browsers */
  box-shadow: none !important; /* no box shadow - for some browsers or if you are using Bootstrap */
}

/* New Site Styles */

body {
  font-family: 'Manrope', sans-serif;
}

p,
h2,
h3,
h4,
h5,
h6 {
  margin: 0px;
  padding: 0px;
}

nav {
  box-shadow: 0px 1px 0px 0px lightgrey;
  padding: 0px 147px;
  height: 95px;
}

.navbar-light .navbar-nav .nav-link {
  color: black;
  padding: 3px 15px;
  border-radius: 15px;
}

.logo {
  width: 124.21px;
  height: 48px;
}

/* ul {
  width: 75%;
  justify-content: center;
} */

.active-menu {
  font-family: 'Inter', Helvetica;
  background-color: #e773431a !important;
  color: #e87443 !important;
  letter-spacing: 0;
  font-size: 16px;
  font-weight: 600;
  border-radius: 10px;
}

.login-btn {
  min-height: 22px;
  border-color: #b5b5be;
  color: black;
  margin-right: 20px;
  font-size: 16px;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.1px;
}

.getStarted-btn {
  background-color: #171725;
  font-family: 'Manrope', Helvetica;
  color: #fafafb;
  font-size: 16px;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.1px;
  min-height: 22px;
  min-width: 135px;
}

.cart-icon-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  gap: 6px;
  margin-left: 24px;
  width: 100%;
}
.cart-btn {
  display: flex;
  align-items: center;
  position: relative;
}
.cart-btn.filled::after {
  content: '';
  display: inline-block;
  position: absolute;
  width: 10px;
  height: 10px;
  top: -4px;
  right: -2px;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  background-color: #03c03c;
}
.cart-price {
  color: #565656;
  font-size: 13px;
  font-weight: 700;
}

.section-1 {
  background-color: #f8f8f8;
}

.section1-head1 {
  font-weight: bold;
  margin-bottom: 20px;
  font-size: 60px;
  padding-top: 100px;
}

.section1-head1-mobile {
  font-weight: bold;
  margin-bottom: 20px;
  font-size: 45px;
  margin: 5%;
  padding-top: 30px;
}

.section1-pera {
  margin-bottom: 28px;
  font-size: 20px;
  font-weight: 400;
  line-height: 32px;
  text-align: center;
}

.section1-pera-mobile {
  margin-bottom: 28px;
  font-size: 18px;
  font-weight: 500;
  line-height: 32px;
  text-align: center;
}

.vector-parent {
  width: 638px;
  height: 24px;
  justify-content: space-around;
}

.vector-parent-mobile {
  font-weight: bold;
  line-height: 170%;
  flex-direction: column;
}

.vector-img {
  margin-right: 11px;
}

.vector-span {
  font-size: 16px;
  font-weight: 600;
}

.vector-span-mobile {
  font-size: 16px;
}

.search {
  margin-top: 49px;
  width: 538px;
  border: 1px solid #efefef;
  height: 64px;
  padding: 2px;
  background: white;
  border-radius: 4px;
}

.search-mobile {
  margin-top: 19px;
  width: 328px;
  border: 1px solid #efefef;
  height: 64px;
  padding: 2px;
  background: white;
  border-radius: 4px;
}

.input-group {
  align-items: center;
  padding: 0px 8px 0px 18px;
}

.find-input {
  width: 75%;
  height: 58px;
  border-radius: 0px;
  border: none;
  font-size: 18px;
}

.find-input-mobile {
  width: 75%;
  height: 58px;
  border-radius: 0px;
  border: none;
  font-size: 18px;
}

.demand-btn {
  width: 173px;
  height: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 4px;
  background: #0062ff;
}

.demand-btn-mobile {
  width: 113px;
  height: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 4px;
  background: #0062ff;
}

.bigImg-1 {
  z-index: 1;
  max-width: 70%;
  margin-bottom: -14px;
}

.drop-down-arrow {
  border: solid black;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  margin-left: 10px;
}

.section-2 {
  padding: 120px 215px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.section-head {
  font-weight: 700;
  font-size: 48px;
  line-height: 68px;
}

.simple-pr {
  margin-bottom: 20px;
  color: orange;
  font-size: 20px;
}

.cards-wrap {
  margin: 56px 0px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.centerCard {
  background-color: #ffffff;
  overflow: hidden;
  text-align: center;
  width: 310px;
  height: 671px;
  border: 1px solid #0062ff;
  border-radius: 10px;
  margin: 0px 40px;
}

.sideCard {
  z-index: 1;
  background-color: #ffffff;
  overflow: hidden;
  text-align: center;
  width: 310px;
  height: 625px;
  border: 1px solid #f1f1f5;
  border-radius: 10px;
}

.header {
  padding: 12px 0px;
  height: 46px;
  background: #0062ff;
  border: 1px solid #0062ff;
}

.card-head5 {
  color: white;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 1px;
  text-align: center;
}

.card-head4 {
  margin-top: 29px;
}

.card-pera {
  font-size: 15px;
  margin-top: 10px;
  color: #696974;
}

.card-heading3 {
  font-size: 40px;
  font-weight: 700;
  margin-top: 24px;
}

.card-details {
  margin: 29px 20px 0px 20px;
  padding-top: 24px;
  border-top: 1px solid #f1f1f5;
}

.card-vector-span {
  font-size: 16px;
  font-weight: 200;
}

.check-wrap {
  margin-bottom: 18px;
}

.card-vector {
  width: 20px;
  margin-right: 5px;
  color: orange;
}

.see-link {
  font-weight: bold;
}

.see-link-wrap {
  width: 263px;
  height: 56px;
  margin-bottom: 120px;
}

.line {
  background-color: #e2e2ea;
  width: 1010px;
  height: 1px;
}

/*----- 3 ------*/

.section-3 {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.btns-wrap {
  margin: 42px 0px 72px 0px;
  display: flex;
  justify-content: space-between;
}

.btns-wrap-home {
  margin: 42px 0px 72px 0px;
  width: 40em;
  display: flex;
  justify-content: space-between;
}

.template-btn {
  height: 47px;
  min-width: 197px;
  border-radius: 50px;
  border: none;
  background-color: #efefef;
  padding: 0px 30px;
  font-size: 15px;
}

.template-btn-active {
  border: 1px solid #e87443;
  background: #fdf1ec;
  color: #e87443;
}

.imgs-wrap {
  display: flex;
  justify-content: space-between;
  width: 1010px;
}

.template-img {
  width: 95%;
}

.tamp-image {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.temp-span {
  font-weight: 500;
  font-size: 16px;
  margin-top: 24px;
  line-height: 19.36px;
}

/*---- footer --*/

.footer {
  margin-top: 100px;
  margin-bottom: 60px;
  display: flex;
}

.footer-logo-wrap {
  width: 18rem;
  margin: 72px 107px 0px 147px;
}

.footer-logo {
  width: 151px;
  margin-left: -1px;
}

.footer-pera {
  font-size: 13px;
  font-weight: 400;
  line-height: 23px;
  text-align: left;
  color: #696974;
}

.item-wrap {
  display: flex;
  margin-top: 84px;
  justify-content: center;
}

.item-wrap-h1 {
  font-size: 14px;
  margin-bottom: 10px;
  color: #92929d;
}

.footer-item {
  text-decoration: none;
  font-size: 14px;
  font-weight: 400;
  line-height: 32px;
  color: #171725;
}

.product {
  margin-right: 76px;
}

.company {
  margin-right: 106px;
}

.social {
  margin-right: 90px;
}

.support {
}

.bottom {
  padding: 0px 147px;
  height: 64px;
  border-top: 1px solid #fbfbfb;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.copyRight {
  font-size: 14px;
  color: #696974;
}

.bottom-img {
  width: 42%;
}

/*------ margins and paddings--------*/

.mr-r-5 {
  margin-right: 5px;
}

.mr-r-10 {
  margin-right: 10px;
}

.mr-r-20 {
  margin-right: 20px;
}

.mr-r-32 {
  margin-right: 32px;
}

.mr-l-20 {
  margin-left: 20px;
}

.mr-t-5 {
  margin-top: 5px;
}

.mr-t-10 {
  margin-top: 10px;
}

.mr-t-20 {
  margin-top: 20px;
}

.mr-t-30 {
  margin-top: 30px;
}

.mr-b-10 {
  margin-bottom: 10px;
}

.mr-b-20 {
  margin-bottom: 20px;
}

.mr-b-30 {
  margin-bottom: 30px;
}

.p-t-b-30 {
  margin-top: 30px;
  margin-bottom: 30px;
}

.pd-t-80 {
  padding-top: 80px;
}

.orange {
  color: orange;
}

@media only screen and (max-width: 450px) {
  .side-image-alignment:before {
    width: 0px;
    height: 0px;
  }
  .side-image-reverse-alignment:before {
    width: 0px;
    height: 0px;
  }
  nav {
    padding: 0px;
    height: 65px;
  }
  .navbar {
    padding: 0px 10px;
  }
  .logo {
    width: 100px;
  }
  .navbar-toggler-icon {
    width: 2.5em;
    height: 2.5em;
  }
  .navbar-toggler {
    padding: 0.1rem 0.2rem;
    font-size: 0.8rem;
  }
  /*----------- 1 ----------*/
  .section1-head1 {
    font-size: 32px;
    padding-top: 35px;
  }
  .section1-pera {
    font-size: 13px;
    margin-bottom: 0px;
  }
  .vector-parent {
    width: auto;
    height: 80px;
    flex-direction: column;
    margin: 20px 0px;
    align-items: flex-start;
  }
  .vector-img {
    width: 14px;
    margin-right: 5px;
  }
  .vector-span {
    font-size: 11px;
    align-self: center;
  }
  .search {
    width: 50%;
    height: 30px;
    margin-top: 20px;
  }
  .input-group {
    padding: 0px;
  }
  .find-input {
    height: 24px;
    font-size: 8px;
  }
  .demand-btn {
    font-size: 7px;
    width: 66px;
  }
  .bigImg-1 {
    width: 100%;
    margin-bottom: -6px;
  }
  .circle-1 {
    display: none;
  }
  .circle-2 {
    display: none;
  }
  .circle-new {
    display: none;
  }
  .section-2 {
    padding: 70px 0px;
  }
  .simple-pr {
    font-size: 10px;
    margin-bottom: 15px;
  }
  .section-head {
    font-weight: 600;
    font-size: 21px;
    line-height: 30px;
  }
  .cards-wrap {
    margin: 35px 0px;
    flex-direction: column;
    align-items: center;
  }
  .centerCard {
    width: 180px;
    height: 320px;
    margin: 10px 0px;
  }
  .sideCard {
    width: 180px;
    height: 320px;
  }
  .header {
    padding: 0px 0px;
    height: 23px;
  }
  .card-head5 {
    font-size: 10px;
  }
  .card-head4 {
    margin-top: 12px;
    font-size: 14px;
  }
  .card-pera {
    font-size: 8px;
    margin-top: 6px;
  }
  .card-heading3 {
    font-size: 30px;
    margin-top: 12px;
  }
  .card-details {
    margin: 17px 10px 0px 10px;
    padding-top: 10px;
  }
  .card-vector {
    width: 10px;
  }
  .card-vector-span {
    font-size: 8px;
  }
  .check-wrap {
    margin-bottom: 7px;
  }
  .see-link {
    font-size: 7px;
  }
  .see-arrow {
    width: 7px;
    margin-left: 5px;
  }
  .see-link-wrap {
    width: 50%;
    display: flex;
    height: auto;
    justify-content: center;
    margin-bottom: 70px;
  }
  .line {
    width: 280px;
  }
  /*-------- 4 -----------*/
  .btns-wrap {
    width: 90%;
    margin: 20px 0px;
  }
  .template-btn {
    height: 20px;
    min-width: 88px;
    padding: 0px 10px;
    font-size: 7px;
  }
  .imgs-wrap {
    flex-direction: column;
    align-items: center;
    width: 90%;
  }
  .tamp-image {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 15px;
    width: 200px;
  }
  .template-img {
    width: 95%;
  }
  .temp-span {
    font-size: 10px;
    margin-top: 5px;
  }
  /*--- Footer ------*/
  .footer {
    flex-direction: column;
    padding: 0px 20px 30px 20px;
    align-items: center;
    margin-bottom: 10px;
    margin-top: 60px;
  }
  .footer-logo-wrap {
    width: 65%;
    text-align: center;
    margin: 0px;
  }
  .footer-logo {
    width: 170px;
    margin-bottom: 5px;
  }
  .footer-pera {
    font-size: 13px;
    line-height: 20px;
  }
  .item-wrap {
    width: 100%;
    margin-top: 50px;
  }
  .product {
    margin-right: 30px;
  }
  .company {
    margin-right: 15px;
  }
  .social {
    margin-right: 30px;
  }
  .item-wrap-h1 {
    font-size: 8px;
    margin-bottom: 5px;
  }
  .footer-item {
    font-size: 8px;
    line-height: 21px;
  }
  .bottom {
    height: 30px;
    padding: 0px 40px;
  }
  .copyRight {
    font-size: 6px;
  }
  .bottom-img {
    width: 150px;
  }
}

@media only screen and (min-width: 451px) and (max-width: 480px) {
  .side-image-alignment:before {
    width: 0px;
    height: 0px;
  }
  .side-image-reverse-alignment:before {
    width: 0px;
    height: 0px;
  }
  nav {
    padding: 0px;
    height: 65px;
  }
  .navbar {
    padding: 0px 10px;
  }
  .logo {
    width: 100px;
  }
  .navbar-toggler-icon {
    width: 2.5em;
    height: 2.5em;
  }
  .navbar-toggler {
    padding: 0.1rem 0.2rem;
    font-size: 0.8rem;
  }
  /*----------- 1 ----------*/
  .section1-head1 {
    font-size: 32px;
    padding-top: 35px;
  }
  .section1-pera {
    font-size: 13px;
    margin-bottom: 0px;
  }
  .vector-parent {
    width: auto;
    height: 80px;
    flex-direction: column;
    margin: 20px 0px;
    align-items: flex-start;
  }
  .vector-img {
    width: 14px;
    margin-right: 5px;
  }
  .vector-span {
    font-size: 11px;
    align-self: center;
  }
  .search {
    width: 50%;
    height: 30px;
    margin-top: 20px;
  }
  .input-group {
    padding: 0px;
  }
  .find-input {
    height: 24px;
    font-size: 8px;
  }
  .demand-btn {
    font-size: 7px;
    width: 66px;
  }
  .bigImg-1 {
    width: 100%;
    margin-bottom: -6px;
  }
  .circle-1 {
    display: none;
  }
  .circle-2 {
    display: none;
  }
  .circle-new {
    display: none;
  }
  .section-2 {
    padding: 70px 0px;
  }
  .simple-pr {
    font-size: 10px;
    margin-bottom: 15px;
  }
  .section-head {
    font-weight: 600;
    font-size: 21px;
    line-height: 30px;
  }
  .cards-wrap {
    margin: 35px 0px;
  }
  .centerCard {
    width: 140px;
    height: 310px;
    margin: 0px 10px;
  }
  .sideCard {
    width: 140px;
    height: 285px;
  }
  .header {
    padding: 2px 0px;
    height: 27px;
  }
  .card-head4 {
    margin-top: 20px;
    font-size: 11px;
  }
  .card-pera {
    font-size: 6px;
    margin-top: 6px;
  }
  .card-heading3 {
    font-size: 30px;
    margin-top: 9px;
  }
  .card-details {
    margin: 17px 10px 0px 10px;
    padding-top: 10px;
  }
  .card-vector {
    width: 8px;
  }
  .card-vector-span {
    font-size: 8px;
  }
  .check-wrap {
    margin-bottom: 3px;
  }
  .see-link {
    font-size: 7px;
  }
  .see-arrow {
    width: 7px;
    margin-left: 5px;
  }
  .see-link-wrap {
    width: 50%;
    display: flex;
    height: auto;
    justify-content: center;
    margin-bottom: 70px;
  }
  .line {
    width: 350px;
  }
  /*-------- 4 -----------*/
  .btns-wrap {
    width: 24rem;
    margin: 20px 0px;
  }
  .template-btn {
    height: 23px;
    min-width: 112px;
    padding: 0px 15px;
    font-size: 9px;
  }
  .imgs-wrap {
    flex-direction: column;
    align-items: center;
    width: 90%;
  }
  .tamp-image {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 15px;
    width: 200px;
  }
  .template-img {
    width: 95%;
  }
  .temp-span {
    font-size: 10px;
    margin-top: 5px;
  }
  /*--- Footer ------*/
  .footer {
    flex-direction: column;
    padding: 0px 20px 30px 20px;
    align-items: center;
    margin-bottom: 10px;
    margin-top: 60px;
  }
  .footer-logo-wrap {
    width: 65%;
    text-align: center;
    margin: 0px;
  }
  .footer-logo {
    width: 170px;
    margin-bottom: 5px;
  }
  .footer-pera {
    font-size: 13px;
    line-height: 20px;
  }
  .item-wrap {
    width: 100%;
    margin-top: 50px;
  }
  .product {
    margin-right: 55px;
  }
  .company {
    margin-right: 65px;
  }
  .social {
    margin-right: 45px;
  }
  .item-wrap-h1 {
    font-size: 9px;
  }
  .footer-item {
    font-size: 9px;
    line-height: 21px;
  }
  .bottom {
    height: 40px;
    padding: 0px 40px;
  }
  .copyRight {
    font-size: 8px;
  }
  .bottom-img {
    width: 170px;
  }
}

@media only screen and (min-width: 481px) and (max-width: 768px) {
  .side-image-alignment:before {
    width: 0px;
    height: 0px;
  }
  .side-image-reverse-alignment:before {
    width: 0px;
    height: 0px;
  }
  nav {
    box-shadow: 0px 1px 0px 0px lightgrey;
    padding: 0px;
    height: 60px;
  }
  .navbar {
    padding: 0px 10px;
  }
  .logo {
    width: 140px;
  }
  /*----------- 1 ----------*/
  .section1-head1 {
    font-size: 30px;
    padding-top: 35px;
  }
  .section1-pera {
    font-size: 15px;
    margin-bottom: 0px;
    line-height: 25px;
  }
  .vector-parent {
    width: auto;
    height: 70px;
    flex-direction: column;
    margin: 20px 0px;
    align-items: flex-start;
  }
  .vector-img {
    width: 13px;
    margin-right: 5px;
  }
  .vector-span {
    font-size: 11px;
  }
  .search {
    width: 260px;
    height: 33px;
    margin-top: 20px;
  }
  .input-group {
    padding: 0px 0px 0px 0px;
  }
  .find-input {
    height: 27px;
    border-radius: 0px;
    border: none;
    font-size: 13px;
  }
  .demand-btn {
    font-size: 9px;
    width: 80px;
  }
  .bigImg-1 {
    z-index: 1;
    margin-bottom: -6px;
    width: 100%;
  }
  .circle-1 {
    display: none;
  }
  .circle-2 {
    display: none;
  }
  .circle-new {
    display: none;
  }
  .section-2 {
    padding: 70px 0px;
  }
  .simple-pr {
    font-size: 13px;
    margin-bottom: 15px;
  }
  .section-head {
    font-weight: 600;
    font-size: 23px;
    line-height: 30px;
  }
  .cards-wrap {
    margin: 40px 0px;
  }
  .centerCard {
    width: 140px;
    height: 325px;
    margin: 0px 10px;
  }
  .sideCard {
    width: 140px;
    height: 300px;
  }
  .header {
    padding: 2px 0px;
    height: 27px;
  }
  .card-head4 {
    margin-top: 20px;
    font-size: 13px;
  }
  .card-pera {
    font-size: 6px;
    margin-top: 6px;
  }
  .card-heading3 {
    font-size: 32px;
    margin-top: 9px;
  }
  .card-details {
    margin: 17px 10px 0px 10px;
    padding-top: 10px;
  }
  .check-wrap {
    margin-bottom: 5px;
  }
  .card-vector {
    width: 12px;
  }
  .card-vector-span {
    font-size: 7px;
  }
  .see-link {
    font-size: 7px;
    font-weight: bold;
  }
  .see-arrow {
    width: 7px;
    margin-left: 5px;
  }
  .see-link-wrap {
    width: 20%;
    display: flex;
    justify-content: center;
    height: auto;
    margin-bottom: 70px;
  }
  .line {
    width: 600px;
  }
  /*-------- 4 -----------*/
  .btns-wrap {
    width: 80%;
    margin: 40px 0px;
    justify-content: space-evenly;
  }
  .template-btn {
    height: 25px;
    min-width: 120px;
    padding: 0px 15px;
    font-size: 8px;
  }
  .imgs-wrap {
    flex-direction: row;
    width: 90%;
  }
  .tamp-image {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 15px;
    width: 200px;
  }
  .template-img {
    width: 95%;
  }
  .temp-span {
    font-size: 9px;
    margin-top: 5px;
  }
  /*--- Footer ------*/
  .footer {
    flex-direction: column;
    padding: 0px 20px 30px 20px;
    align-items: center;
    margin-bottom: 10px;
    margin-top: 60px;
  }
  .footer-logo-wrap {
    width: 39%;
    text-align: center;
    margin: 0px;
  }
  .footer-logo {
    width: 170px;
    margin-bottom: 5px;
  }
  .footer-pera {
    font-size: 13px;
    line-height: 20px;
  }
  .product {
    margin-right: 55px;
  }
  .company {
    margin-right: 65px;
  }
  .social {
    margin-right: 45px;
  }
  .item-wrap {
    width: 100%;
    margin-top: 50px;
  }
  .item-wrap-h1 {
    font-size: 9px;
  }
  .footer-item {
    font-size: 9px;
    line-height: 21px;
  }
  .bottom {
    height: 50px;
    padding: 0px 40px;
  }
  .copyRight {
    font-size: 10px;
  }
  .bottom-img {
    width: 200px;
  }
}

@media only screen and (min-width: 769px) and (max-width: 1024px) {
  .side-image-alignment:before {
    width: 0px;
    height: 0px;
  }
  .side-image-reverse-alignment:before {
    width: 0px;
    height: 0px;
  }
  nav {
    box-shadow: 0px 1px 0px 0px lightgrey;
    padding: 10px 5px;
  }
  .logo {
    width: 140px;
  }
  /*----------- 1 ----------*/
  .section1-head1 {
    font-size: 60px;
    padding-top: 50px;
  }
  .section1-pera {
    font-size: 24px;
    margin-bottom: 0px;
  }
  .vector-parent {
    width: 85;
    margin: 20px 0px;
  }
  .vector-img {
    width: 20px;
    margin-right: 8px;
  }
  .vector-span {
    font-size: 18px;
  }
  .search {
    width: 55%;
    height: 50px;
    margin-top: 30px;
  }
  .find-input {
    height: 44px;
    border-radius: 0px;
    border: none;
    font-size: 16px;
  }
  .demand-btn {
    font-size: 16px;
    border: none;
    border-radius: 3px !important;
  }
  .bigImg-1 {
    z-index: 1;
    margin-bottom: -6px;
    width: 100%;
  }
  .circle-1 {
    display: none;
  }
  .circle-2 {
    display: none;
  }
  .circle-new {
    display: none;
  }
  .section-2 {
    padding: 50px 0px;
  }
  .simple-pr {
    font-size: 20px;
    margin-bottom: 20px;
  }
  .section-head {
    font-weight: 600;
    font-size: 44px;
    line-height: 52px;
  }
  .cards-wrap {
    margin: 40px 0px;
  }
  .centerCard {
    width: 160px;
    height: 345px;
    margin: 0px 10px;
  }
  .sideCard {
    width: 160px;
    height: 320px;
  }
  .header {
    padding: 2px 0px;
    height: 27px;
  }
  .card-head4 {
    margin-top: 20px;
    font-size: 15px;
  }
  .card-pera {
    font-size: 7px;
    margin-top: 6px;
  }
  .card-heading3 {
    font-size: 33px;
    margin-top: 9px;
  }
  .card-details {
    margin: 17px 10px 0px 10px;
    padding-top: 10px;
  }
  .check-wrap {
    margin-bottom: 3px;
  }
  .card-vector {
    width: 14px;
  }
  .card-vector-span {
    font-size: 7px;
  }
  .see-link {
    font-size: 16px;
    font-weight: bold;
  }
  .see-arrow {
    width: 15px;
    margin-left: 5px;
  }
  .see-link-wrap {
    width: 70%;
  }
  .line {
    width: 550px;
  }
  /*-------- 4 -----------*/
  .btns-wrap {
    width: 70%;
    margin: 50px 0px;
  }
  .template-btn {
    height: 50px;
    min-width: 180px;
    padding: 0px 15px;
    font-size: 13px;
  }
  .imgs-wrap {
    flex-direction: row;
    width: 100%;
  }
  .tamp-image {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 15px;
    width: 200px;
  }
  .template-img {
    width: 95%;
  }
  .temp-span {
    font-size: 16px;
    margin-top: 10px;
  }
  /*--- Footer ------*/
  .footer {
    display: flex;
    flex-direction: column;
    padding: 50px 20px 30px 20px;
    align-items: center;
  }
  .footer-logo-wrap {
    width: 55%;
    text-align: center;
  }
  .footer-logo {
    width: 170px;
    margin-bottom: 5px;
  }
  .footer-pera {
    font-size: 22px;
    line-height: 29px;
  }
  .item-wrap {
    width: 90%;
    margin-top: 50px;
  }
  .item-wrap-h1 {
    font-size: 18px;
  }
  .footer-item {
    font-size: 18px;
    line-height: 35px;
  }
  .bottom {
    height: 50px;
    padding: 0px 40px;
  }
  .copyRight {
    font-size: 12px;
  }
  .bottom-img {
    width: 250px;
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1200px) {
  nav {
    box-shadow: 0px 1px 0px 0px lightgrey;
    padding: 10px 5px;
  }
  .logo {
    width: 140px;
  }
  /*----------- 1 ----------*/
  .section1-head1 {
    font-size: 60px;
    padding-top: 50px;
  }
  .section1-pera {
    font-size: 24px;
    margin-bottom: 0px;
  }
  .vector-parent {
    width: 85;
    margin: 20px 0px;
  }
  .vector-img {
    width: 20px;
    margin-right: 8px;
  }
  .vector-span {
    font-size: 18px;
  }
  .search {
    width: 55%;
    height: 50px;
    margin-top: 30px;
  }
  .find-input {
    height: 44px;
    border-radius: 0px;
    border: none;
    font-size: 16px;
  }
  .demand-btn {
    font-size: 16px;
    border: none;
    border-radius: 3px !important;
  }
  .bigImg-1 {
    z-index: 1;
    margin-bottom: -6px;
    width: 100%;
  }
  .circle-1 {
    display: none;
  }
  .circle-2 {
    display: none;
  }
  .circle-new {
    display: none;
  }
  .section-2 {
    padding: 50px 0px;
  }
  .simple-pr {
    font-size: 20px;
    margin-bottom: 20px;
  }
  .section-head {
    font-weight: 600;
    font-size: 50px;
  }
  .see-link {
    font-size: 16px;
    font-weight: bold;
  }
  .see-arrow {
    width: 15px;
    margin-left: 5px;
  }
  .see-link-wrap {
    padding-bottom: 70px;
    width: 70%;
    border-bottom: 1px solid #efefef;
  }
  /*-------- 4 -----------*/
  .btns-wrap {
    width: 70%;
    margin: 50px 0px;
  }
  .template-btn {
    min-width: 220px;
    padding: 0px 15px;
    font-size: 15px;
  }
  .imgs-wrap {
    flex-direction: row;
    width: 80%;
  }
  .tamp-image {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 15px;
    width: 200px;
  }
  .template-img {
    width: 95%;
  }
  .temp-span {
    font-size: 16px;
    margin-top: 10px;
  }
  /*--- Footer ------*/
  .footer {
    display: flex;
    padding: 100px 30px 30px 30px;
    align-items: flex-start;
  }
  .footer-link-cr {
    font-size: 12px;
    color: #00003b;
  }
  .footer-logo-wrap {
    width: 48%;
    margin: 72px 135px 0px 0px;
    background: antiquewhite;
  }
  .footer-logo {
    width: 145px;
  }
  .footer-pera {
    font-size: 16px;
    line-height: 25px;
  }
  .item-wrap {
    width: 90%;
  }
  .item-wrap-h1 {
    font-size: 15px;
  }
  .footer-item {
    font-size: 18px;
    line-height: 35px;
  }
  .bottom {
    height: 50px;
    padding: 0px 40px;
  }
  .copyRight {
    font-size: 15px;
  }
  .bottom-img {
    width: 300px;
  }
}
.container-fluid {
  padding-right: 0;
  padding-left: 0;
}

/* Sidebar */

.sidebar {
  font-family: 'Manrope', Helvetica;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 250px;
}
.sidearBody {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 14px;
  justify-content: center;
  min-height: 536px;
  width: 249px;
}
.sidebarContainer {
  height: 470px;
  margin-left: -1px;
  position: relative;
  width: 251px;
}
.sidebarItemContainer {
  align-items: center;
  display: flex;
  gap: 5px;
  height: 54px;
  left: 0;
  min-width: 251px;
  top: 156px;
  cursor: pointer;
}
.sidebarItem {
  fill: #92929d;
  align-items: center;
  border-radius: 8px;
  display: flex;
  gap: 20px;
  height: 44px;
  min-width: 231px;
  padding: 0 11px;
}
.menu-active {
  color: #e77343;
  font-family: 'Manrope', Helvetica;
  font-size: 14px;
  font-weight: 800;
  letter-spacing: 0.1px;
  line-height: normal;
  margin-bottom: 1px;
  min-height: 19px;
  min-width: 116px;
}
.activeItem {
  fill: #e77343;
  align-items: center;
  background-color: #e773430f;
  border-radius: 8px;
  display: flex;
  gap: 20px;
  height: 44px;
  min-width: 231px;
  padding: 0 11px;
}
.newLogo {
  height: 46px;
  margin-left: 11px;
  object-fit: cover;
  width: 119px;
}
.manrope-medium-mirage-14px {
  color: #171725;
  font-family: 'Manrope', Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.selector {
  background-color: #e77343;
  border: 0px none;
  border-radius: 0px 100px 100px 0px;
  height: 34px;
  width: 5px;
}
.emptySelector {
  height: 34px;
  width: 5px;
}
.sidebarLogout {
  color: #fc5a5a !important;
  font-weight: 600;
  letter-spacing: 0.1px;
  line-height: normal;
  margin-bottom: 1px;
  min-height: 19px;
  min-width: 48px;
}
.sidebarIcon {
}
/*
.sidebarItemContainer.logout p{
  color: #FC5A5A;
}

.sidebarItemContainer:hover {
  font-weight: bold;
  background-color: #FFF7F4;
}

.sidebarItemContainer:hover {
  border-left: 7px solid #E87443;
  border-radius: 3px;
  margin-right: 20px;
}

.sidebarItemContainer img {
  fill: #E87443;
  height: 55px;
  width: 55px;
  padding-left: 1rem;
  padding-right: 1rem;
}

.sidebarItemContainer p {
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  font-weight: 500;
  text-align: left;
  color: #171725;
  cursor: pointer;
}

.sidebarItemContainer:hover p {
  font-family: 'Montserrat';
  font-size: 14px;
  font-weight: bold;
  line-height: 19px;
  letter-spacing: 0.10000000149011612px;
  text-align: left;
  color: #E87443;
  cursor: pointer;
}
.sidebarItemContainer:hover g {
  fill: #E87443;
}
.sidebarItemContainer:hover path {
  stroke: #E87443;
}
*/
