.introSection {
  padding-bottom: 0px !important;
}
.host-section-2 {
  margin-top: 180px;
}
.cart-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.feature-corner {
  font-size: 16px;
  color: #e87443;
  display: flex;
  margin-bottom: 5px;
  font-weight: 700;
  align-items: center;
}
.f-editor {
  margin-right: 10px;
}

.cart-text h1 {
  font-size: 42px;
  margin-bottom: 30px;
  position: relative;
}

.cart-text p {
  font-size: 18px;
  margin-bottom: 20px;
  color: #696974;
  max-width: 400px;
}

.cart-text a {
  color: blue;
  font-size: 18px;
  font-weight: 700;
}
.side-image-alignment {
  margin-left: -30px;
  padding-left: -15px;
  position: relative;
}

.blue-section {
  display: flex;
  justify-content: space-evenly;
  margin-top: 100px;
  background: #0062ff;
  padding: 80px 0px;
  color: #fff;
  align-items: center;
  border-radius: 10px;
}
.blue-section p {
  max-width: 500px;
}
.blue-section button {
  font-size: 15px;
  color: #0062ff;
  background-color: #fff;
  border: none;
}

.side-image-alignment:before {
  content: '';
  width: 580px;
  height: 45%;
  position: absolute;
  top: -120px;
  background-image: url(../../../images/marketing/featureImages/designVector.svg);
  background-repeat: no-repeat;
  z-index: 100;
  left: 250px;
}
.side-image-reverse-alignment:before {
  content: '';
  width: 580px;
  height: 45%;
  position: absolute;
  transform: scaleX(-1);
  top: -120px;
  background-image: url(../../../images/marketing/featureImages/designVector.svg);
  background-repeat: no-repeat;
  z-index: 100;
  left: 250px;
}

@media (max-width: 767.98px) {
  .side-image-alignment:before {
    width: 0px;
    height: 0px;
  }
  .side-image-reverse-alignment:before {
    width: 0px;
    height: 0px;
  }
  .host-section-2 {
    width: 80%;
  }
  .blue-section {
    flex-direction: column;
    padding: 20px;
    justify-content: center;
    align-items: center;
    width: 80%;
  }
  .blue-section h1 {
    font-size: 20px;
    margin-bottom: 20px;
  }
  .blue-section p {
    font-size: 12px;
    margin-bottom: 20px;
  }
  .circle-1 {
    display: none;
  }
  .circle-2 {
    display: none;
  }
  .circle-3 {
    display: none;
  }
  .circle-4 {
    display: none;
  }
}

.section1-head1 {
  font-size: 60px;
  padding-top: 100px;
  margin-bottom: 30px;
  line-height: 82px;
}

.section1-pera {
  margin-bottom: 30px;
  line-height: 30px;
  font-size: 20px;
  color: #696974;
}
.demand-btn {
  font-size: 16px;
  width: 192px;
  height: 55px;
}
.btns-col-wrap {
  margin-top: 10px;
}
.btns-wrap {
  width: 50%;
  flex-direction: column;
  align-items: center;
}
.feature-btn {
  padding: 10px 22px;
  box-sizing: unset;
  font-size: 16px;
  border-radius: 30px;
  background-color: white;
  border: 1px solid #f1f1f5;
}
.wrap {
}
.circle-1 {
  position: absolute;
  top: 141px;
  left: 325px;
  width: 80px;
  height: 80px;
}
.circle-2 {
  height: 160px;
  width: 160px;
  left: 173px;
  top: 375px;
}
.circle-3 {
  height: 380px;
  width: 380px;
  left: 1060px;
  top: 350px;
  border-radius: 0px;
}
.circle-4 {
  position: absolute;
  height: 50px;
  width: 50px;
  left: 1081px;
  top: 251px;
  border-radius: 0px;
}
.row {
  margin-left: 0px;
  margin-right: 0px;
}
@media only screen and (max-width: 320px) {
  .section1-head1 {
    padding-top: 50px;
    margin-bottom: 0px;
    font-size: 23px;
    line-height: 50px;
  }

  .section1-pera {
    width: 80%;
    margin-bottom: 10px;
    line-height: 18px;
    font-size: 11px;
  }
  .demand-btn {
    font-size: 7px;
    width: 70px;
    height: 21px;
  }
  .btns-wrap {
    width: 80%;
    flex-direction: column;
    align-items: center;
  }
  .feature-btn {
    padding: 4px 6px;
    box-sizing: unset;
    font-size: 5px;
    border-radius: 30px;
    background-color: white;
    border: 1px solid #f1f1f5;
  }
  .circle-4 {
    display: none;
  }
}
@media only screen and (min-width: 321px) and (max-width: 450px) {
  .section1-head1 {
    margin-bottom: 13px;
    font-size: 35px;
  }

  .section1-pera {
    width: 73%;
    margin-bottom: 10px;
    line-height: 23px;
  }
  .demand-btn {
    font-size: 10px;
    width: 84px;
    height: 29px;
  }
  .btns-col-wrap {
    margin-top: 5px;
  }
  .btns-wrap {
    width: 90%;
    flex-direction: column;
    align-items: center;
  }
  .feature-btn {
    padding: 7px 6px;
    box-sizing: unset;
    font-size: 6px;
    border-radius: 30px;
    background-color: white;
    border: 1px solid #f1f1f5;
  }
  .circle-4 {
    display: none;
  }
}

@media only screen and (min-width: 451px) and (max-width: 480px) {
  .section1-head1 {
    margin-bottom: 13px;
    font-size: 35px;
  }

  .section1-pera {
    width: 73%;
    margin-bottom: 10px;
    line-height: 23px;
  }
  .demand-btn {
    font-size: 10px;
    width: 84px;
    height: 29px;
  }
  .btns-col-wrap {
    margin-top: 10px;
  }
  .btns-wrap {
    width: 90%;
    flex-direction: column;
    align-items: center;
  }
  .feature-btn {
    padding: 8px 10px;
    box-sizing: unset;
    font-size: 8px;
    border-radius: 30px;
    background-color: white;
    border: 1px solid #f1f1f5;
  }
  .circle-4 {
    display: none;
  }
}

@media only screen and (min-width: 481px) and (max-width: 730px) {
  .section1-head1 {
    font-size: 40px;
    padding-top: 60px;
  }

  .section1-pera {
    width: 57%;
    margin-bottom: 20px;
    line-height: 18px;
    font-size: 12px;
  }
  .demand-btn {
    font-size: 12px;
    width: 110px;
    height: 32px;
  }
  .btns-col-wrap {
    margin-top: 10px;
  }
  .btns-wrap {
    width: 80%;
    flex-direction: column;
    align-items: center;
  }
  .feature-btn {
    padding: 10px 10px;
    box-sizing: unset;
    font-size: 8px;
    border-radius: 30px;
    background-color: white;
    border: 1px solid #f1f1f5;
  }
  .circle-4 {
    display: none;
  }

  .f-section-2 {
    padding-top: 20px;
  }
  .feature-card {
    width: 85%;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .feature-corner {
    font-size: 8px;
    color: #e87443;
    margin-bottom: 5px;
    font-weight: 700;
  }
}
@media only screen and (min-width: 731px) and (max-width: 768px) {
  .side-image-alignment:before {
    width: 0px;
    height: 0px;
  }
  .side-image-reverse-alignment:before {
    width: 0px;
    height: 0px;
  }
  .section1-head1 {
    font-size: 50px;
    padding-top: 60px;
  }

  .section1-pera {
    width: 48%;
    margin-bottom: 20px;
    line-height: 22px;
    font-size: 16px;
  }
  .demand-btn {
    font-size: 12px;
    width: 110px;
    height: 32px;
  }
  .btns-col-wrap {
    margin-top: 10px;
  }
  .btns-wrap {
    width: 80%;
    flex-direction: column;
    align-items: center;
  }

  .feature-btn {
    padding: 10px 15px;
    box-sizing: unset;
    font-size: 13px;
    border-radius: 30px;
    background-color: white;
    border: 1px solid #f1f1f5;
  }
  .circle-4 {
    display: none;
  }
}

@media only screen and (min-width: 769px) and (max-width: 992px) {
  .side-image-alignment:before {
    width: 0px;
    height: 0px;
  }
  .side-image-reverse-alignment:before {
    width: 0px;
    height: 0px;
  }
  .section1-head1 {
    font-size: 50px;
    padding-top: 60px;
  }

  .section1-pera {
    width: 48%;
    margin-bottom: 20px;
    line-height: 22px;
    font-size: 16px;
  }
  .demand-btn {
    font-size: 12px;
    width: 110px;
    height: 32px;
  }
  .btns-wrap {
    width: 80%;
    flex-direction: column;
    align-items: center;
  }
  .btns-col-wrap {
    margin-top: 10px;
  }
  .feature-btn {
    padding: 10px 15px;
    box-sizing: unset;
    font-size: 13px;
    border-radius: 30px;
    background-color: white;
    border: 1px solid #f1f1f5;
  }
  .circle-4 {
    display: none;
  }
}
@media only screen and (min-width: 993px) and (max-width: 1024px) {
  .section1-head1 {
    font-size: 50px;
    padding-top: 60px;
  }

  .section1-pera {
    width: 38%;
    margin-bottom: 20px;
    line-height: 22px;
    font-size: 16px;
  }
  .demand-btn {
    font-size: 15px;
    width: 138px;
    height: 40px;
  }
  .btns-col-wrap {
    margin-top: 10px;
  }
  .btns-wrap {
    width: 60%;
    flex-direction: column;
    align-items: center;
  }
  .feature-btn {
    padding: 10px 15px;
    box-sizing: unset;
    font-size: 13px;
    border-radius: 30px;
    background-color: white;
    border: 1px solid #f1f1f5;
  }
  .circle-4 {
    display: none;
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1200px) {
  .section1-head1 {
    font-size: 60px;
    padding-top: 100px;
    margin-bottom: 30px;
  }

  .section1-pera {
    width: 38%;
    margin-bottom: 30px;
    line-height: 30px;
    font-size: 20px;
  }
  .demand-btn {
    font-size: 16px;
    width: 150px;
    height: 40px;
  }
  .btns-col-wrap {
    margin-top: 10px;
  }
  .btns-wrap {
    width: 80%;
    flex-direction: column;
    align-items: center;
  }
  .feature-btn {
    padding: 10px 24px;
    box-sizing: unset;
    font-size: 18px;
    border-radius: 30px;
    background-color: white;
    border: 1px solid #f1f1f5;
  }
  .circle-4 {
    display: none;
  }
}
