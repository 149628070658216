.search {
  width: 641px;
  padding: 0px;
  margin-bottom: 64px;
  overflow: hidden;
}

.searchResults {
  width: 741px;
  padding: 0px;
  margin-bottom: 64px;
  overflow: hidden;
}

.input-group {
  padding: 0px 0px 0px 18px;
}

.find-input {
  height: 100%;
}

.domain-img-wrap {
  height: 100px;
  margin-top: 10px;
}

.demand-btn-search {
  width: 94px;
  height: 100%;
  border-radius: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  background: #0062ff;
}

.domain-card {
  background: #ffffff;
  width: 286px;
  height: 524px;
  border: 1px solid #f1f1f5;
  padding: 20px;
  margin-top: 100px;
  font-size: 12px;
}

.biz {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.com {
  border-radius: 0px;
}

.org {
  border-radius: 0px;
}

.net {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.domain-card-btn {
  width: 226px;
  height: 48px;
  color: #e87443;
  background: #ffffff;
  border: 1px solid #f2d0c2;
  box-sizing: border-box;
  border-radius: 4px;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.1px;
}

.domain-card-pera {
  width: 80%;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.10000000149011612px;
  text-align: left;
  color: #696974;
}

.domain-card-pera p {
  margin-top: 20px;
  margin-bottom: 95px;
  font-family: 'Inter', sans-serif;
  font-size: 15px;
  line-height: 22px;
  letter-spacing: 0.1px;
}

.domain-card-pera span {
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 27px;
  letter-spacing: 0.10000000149011612px;
  text-align: center;
}

.years {
  margin-bottom: 60px;
}

.domain-h2 {
  margin-top: 10px;
}

.start-from {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 22px;
  letter-spacing: 0.1px;
  color: #696974;
}

.domain-circle-1 {
  position: absolute;
  top: 46px;
  left: 295px;
  width: 60px;
  height: 60px;
}

.domain-circle-2 {
  position: absolute;
  top: 220px;
  left: 193px;
  width: 120px;
  height: 120px;
}

.domain-circle-3 {
  position: absolute;
  top: 258px;
  right: 5%;
  width: 230px;
  height: 230px;
}

.domain-circle-4 {
  position: absolute;
  top: 206px;
  right: 21.5%;
}

#domain-section-1 {
  margin-bottom: 0px;
  padding-bottom: 0px;
}

@media only screen and (max-width: 450px) {
  .row {
    justify-content: center;
  }
  .search {
    width: 60%;
  }
  .demand-btn {
    padding: 0px;
    width: 40px;
  }
  .demand-btn img {
    width: 15px;
  }
  .biz {
    border-radius: 10px;
  }
  .com {
    border-radius: 10px;
  }

  .org {
    border-radius: 10px;
  }
  .net {
    border-radius: 10px;
  }
}

@media only screen and (min-width: 451px) and (max-width: 480px) {
  .row {
    justify-content: center;
  }

  .search {
    width: 60%;
  }

  .demand-btn {
    padding: 0px;
    width: 40px;
  }

  .demand-btn img {
    width: 18px;
  }

  .biz {
    border-radius: 10px;
  }

  .com {
    border-radius: 10px;
  }

  .org {
    border-radius: 10px;
  }

  .net {
    border-radius: 10px;
  }
}

@media only screen and (min-width: 481px) and (max-width: 767px) {
  .row {
    justify-content: center;
  }

  .search {
    width: 60%;
  }

  .demand-btn {
    padding: 0px;
    width: 40px;
  }

  .demand-btn img {
    width: 18px;
  }

  .biz {
    border-radius: 10px;
  }

  .com {
    border-radius: 10px;
  }

  .org {
    border-radius: 10px;
  }

  .net {
    border-radius: 10px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .search {
    width: 60%;
  }

  .demand-btn {
    padding: 0px;
    width: 40px;
  }

  .demand-btn img {
    width: 18px;
  }

  .domain-img-wrap img {
    width: 50px;
  }

  .years {
    margin-bottom: 45px;
  }

  .domain-card-pera span {
    font-size: 20px;
  }

  .domain-img-wrap {
    height: 60px;
  }

  .domain-card-pera p {
    margin-top: 10px;
    margin-bottom: 50px;
    font-size: 12px;
    line-height: 13px;
  }

  .start-from {
    font-size: 12px;
  }

  .domain-h2 {
    font-size: 25px;
  }

  .domain-card-btn {
    width: 100%;
    height: 40px;
    align-self: center;
  }

  .domain-card {
    height: 370px;
    display: flex;
    flex-direction: column;
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1200px) {
  .domain-card-btn {
    width: 100%;
    height: 40px;
    align-self: center;
  }
}
