.section-1 {
  margin-bottom: 200px;
}

.my-card {
  padding: 48px 98px;
  max-width: 1010px;
  transform: translateY(15%);
  border-radius: 10px;
  margin: 0px 3rem;
}

.my-card-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 50px;
}

.my-card-header h1 {
  font-size: 56px;
  text-align: center;
  color: #171725;
}

.my-card-header p {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 100;
  font-size: 20px;
  line-height: 32px;
  max-width: 456px;
  text-align: center;
  color: #696974;
}

.margin-top-20 {
  margin-top: 20px;
}

.margin-top-10 {
  margin-top: 10px;
}

.circle-1 {
  position: absolute;
  top: 161px;
  left: 350px;
  width: 80px;
  height: 80px;
}

.circle-2 {
  height: 160px;
  width: 160px;
  left: 147px;
  top: 801px;
}

.circle-3 {
  height: 380px;
  width: 380px;
  left: 1060px;
  top: 350px;
  border-radius: 0px;
}

.circle-4 {
  position: absolute;
  height: 40px;
  width: 40px;
  left: 951px;
  top: 131px;
  border-radius: 0px;
}

textarea.form-control {
  min-height: 160px;
}

@media (max-width: 767.98px) {
  .my-card-header {
    padding: 20px;
  }
  .my-card-header h1 {
    font-size: 36px;
  }
  .my-card-header p {
    font-size: 12px;
    line-height: 22px;
    /* or 160% */
  }
}
