.section-1 {
  padding-bottom: 100px;
}

.sideCard {
  margin-top: 100px;
}
.customize-section {
  margin-bottom: 100px;
  border: 1px solid #f1f1f5;
  padding: 0px;
  padding-bottom: 100px;
}
.custom-price-header {
  font-size: 38px;
  background: #fafafb;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 50px;
  padding-bottom: 50px;
  text-align: center;
}
.custom-price-header .price {
  margin-top: 30px;
  display: block;
  color: #0062ff;
}
.custom-price-header div {
  max-width: 600px;
}
.price-hightlight {
  font-weight: bold;
}

.checkbox-wrapper {
  display: flex;
  font-size: 16px;
  justify-content: space-between;
}
.checkbox-wrapper label {
  padding-left: 5px;
}

.checkbox-wrapper .price {
  font-weight: bold;
}

.custom-price-body {
  display: flex;
  justify-content: space-around;
  margin-top: 48px;
}

.price-column {
  width: 25%;
}
.price-column .checkbox-wrapper {
  margin-top: 10px;
  margin-bottom: 12px;
}

.quat-btn-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.circle-1 {
  position: absolute;
  top: 141px;
  left: 315px;
  width: 80px;
  height: 80px;
}
.circle-2 {
  position: absolute;
  top: 375px;
  left: 173px;
  width: 160px;
  height: 160px;
}
.circle-3 {
  position: absolute;
  top: 350px;
  right: 0%;
  width: 380px;
  height: 380px;
}
.circle-4 {
  position: absolute;
  top: 251px;
  right: 21.5%;
}
